import axios from "axios";

// import lib
import config from "../lib/config/config";
import { getAuthToken } from "../lib/localStorage";
axios.defaults.headers.common["Authorization"] = getAuthToken();

export const getAddress = async (token, dispatch) => {
    // console.log("yes", `${config.baseUrl}/api/getAddress`);
    try {
      let respData = await axios({
        method: "get",
        url: `${config.baseUrl}/api/getAddress`,
      });
      return {
        loading: false,
        userValue: respData.data.addressData,
      };
    } catch (err) {
      return {
        loading: false,
        error: err.response.data.errors,
      };
    }
  };
  
  export const addLottery = async (data) => {
    try {
      let respData = await axios({
        method: "post",
        url: `${config.baseUrl}/api/addLottery`,
        data,
      });
  
      return {
        loading: false,
      };
    } catch (err) {
      return {
        loading: false,
        error: err.response.data.errors,
      };
    }
  };

export const addLotterySettings = async (data) => {
    try {
      let respData = await axios({
        method: "post",
        url: `${config.baseUrl}/api/addLotterySettings`,
        data,
      });
  
      return {
        loading: false,
      };
    } catch (err) {
      return {
        loading: false,
        error: err.response.data.errors,
      };
    }
  };
  
  export const getlotterylist = async (token, dispatch) => {
    console.log("yes");
    try {
      let respData = await axios({
        method: "get",
        url: `${config.baseUrl}/api/getlotterylist`,
      });
      return {
        loading: false,
        userValue: respData.data.addressData,
      };
    } catch (err) {
      return {
        loading: false,
        error: err.response.data.errors,
      };
    }
  };

  export const completeTicket = async (data, dispatch) => {
    console.log("yes");
    try {
      let respData = await axios({
        method: "post",
        url: `${config.baseUrl}/api/completeTicket`,
        data,
      });
      return {
        loading: false,
        userValue: respData.data.userValue,
      };
    } catch (err) {
      return {
        loading: false,
        error: err.response.data.errors,
      };
    }
  };
  
  export const closeComTicket = async (data, dispatch) => {
    console.log("yessaran");
    try {
      let respData = await axios({
        method: "post",
        url: `${config.baseUrl}/api/closeComTicket`,
        data,
      });
  
      console.log(respData, "respDatarespDatarespDatarespDatarespData");
      return {
        loading: false,
        userValue: respData.data.userValue,
      };
    } catch (err) {
      return {
        loading: false,
        error: err.response.data.errors,
      };
    }
  };