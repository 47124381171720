import React, {useEffect,useState,useRef} from "react";
import { Button, TextField } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from 'react-router-dom'

import Web3 from 'web3';
import WalletConnectProvider from "@walletconnect/web3-provider";

import $ from 'jquery';
import axios from 'axios';
import config from '../../lib/config/config';
import {getprovider} from '../seperate/provider';
// import LoderImg from '../../assets/dev/images/loader.gif'
//import styles from "assets/jss/material-kit-react/views/landingPage.js";
// import Modal from 'react-modal';
// https://data-seed-prebsc-1-s1.binance.org:8545/
// import {
//   AddLikeAction,
//   GetLikeDataAction
// } from '../../actions/v1/token';
import {
  AddressUserDetails_GetOrSave_Action,
  Collectibles_Get_Action,
  changeReceiptStatus_Action
} from '../../actions/User';
import { toast } from 'react-toastify';
// toast.configure();
let toasterOption = config.toasterOption;

//const useStyles = makeStyles(styles);

export default function ConnectWallet(props) {

  //const classes = useStyles();
  const { ...rest } = props;

  const {
    WalletConnected
  } = props;

  const timerRef = useRef(null);


  useEffect(() => {
    getInit();
  }, []);
  async function getInit() {
    //connect_Wallet('metamask');
  }

  let web3
  // head to blocknative.com to create a key
  const BLOCKNATIVE_KEY = 'blocknative-api-key'
  // the network id that your dapp runs on
  const NETWORK_ID = 1
  var provider= config.liverpcUrls;

  async function connect_Wallet(type = 'metamask') {
    window.$('.modal').modal('hide');
   var {provider, address} = await getprovider();
      if(provider){
      if(type == 'walletconnect') {
          provider = new WalletConnectProvider({
            rpc: {
              56: "https://bsc.rpc.blxrbdn.com",
            },
            chainId: 56,
          });
      }
      else if(type == 'metamask') {
        provider = window.ethereum;
        var web3 = new Web3(provider);
        if (typeof web3 !== 'undefined') {
          
        }
        else {
          props.Set_WalletConnected(false);
          toast.warning("Connect to Binance Network", toasterOption);
          return false;
        }
      }
    }
      if (provider) {
        try {
            await provider.enable();
            const web3 = new Web3(window.web3.currentProvider)
            let chainid = await web3.eth.getChainId();
            console.log(chainid,"====config.networkVersion===",config.networkVersion)
            if(chainid == config.networkVersion){
              // if (window.web3.currentProvider.isMetaMask === true) {
                if(web3) {
                  var currAddr = await web3.eth.getAccounts();
                  currAddr = currAddr[0];
                  console.log(currAddr,"currAddrcurrAddrcurrAddr")
                  console.log(currAddr,"currAddr")
                  props.Set_UserAccountAddr(currAddr);
                  props.Set_WalletConnected(true);
                  var result = await web3.eth.getAccounts()
                  var setacc = result[0];
                  props.Set_Accounts(setacc);
                  web3.eth.getBalance(setacc)
                  .then(val => {
                    var balance = val / 1000000000000000000;
                    props.Set_UserAccountBal(balance);
                    AfterWalletConnected();
                  })
                }
              // }
            }
            else {
              props.Set_WalletConnected(false);
              toast.warning("Please Connect to Binance Network1", toasterOption);
            }

        } catch (err) {
          props.Set_WalletConnected(false);
        }
      }
      else {
        props.Set_WalletConnected(false);
        // toast.warning("Please Add (or) connect wallet", toasterOption);
      }
  }
  async function AfterWalletConnected() {
    await AddressUserDetails_GetOrSave_Call();
    props.AfterWalletConnected();
  }
  async function AddressUserDetails_GetOrSave_Call() {
            const web3 = new Web3(window.web3.currentProvider)

    var addr = await web3.eth.getAccounts();
    // addr = addr[0];

    console.log(addr,"addr");
    var ReqData = { addr : addr[0] , provider: provider}

    console.log(ReqData,"ReqData")
    var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
    console.log('------ 1q1q1q', Resp);
    if (Resp && Resp.data && Resp.data.data) {      
      props.Set_AddressUserDetails(Resp.data.data.User);
    } else {
      props.Set_AddressUserDetails({});
    }
    return true;
  }

  

  return (
    <div>
      {props.fullLoader && <div class="loading"></div>}
      {/* {(
        (WalletConnected == false || WalletConnected == 'false')
        &&
        (
          location_pathname == 'my-items'
          || location_pathname == 'following'
          || location_pathname == 'activity'
          || location_pathname == 'info'
        )
      ) &&
      <Modal
        isOpen={true}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="modaltest" style={{opacity:2, position:'relative'}}>
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Network</h2>
          <div>Connect to Binance Network.</div>
        </div>
      </Modal>
      } */}
      {/* <div id="connect_Wallet_call" onClick={() => connect_Wallet_call('metamask')}></div> */}
    </div>
  )
}