import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import Home from "./pages/home";
import Setting from "./pages/setting";
import LotteryList from "./pages/LotteryList";
import { toast, ToastContainer } from "react-toastify";
import Prediction from "./pages/prediction";
import Chart from "./pages/chart";
const App = () => {
  const routes = useRoutes([{ path: "/", element: <Home /> },
  // { path: "/setting", element: <Setting /> },
  // { path: "/LotteryList", element: <LotteryList /> },
  { path: "/prediction", element: <Prediction /> },
  { path: "/chart", element: <Chart /> }]);
  return routes;
};

// AOS.init();

const AppWrapper = () => {
  return (
    <div>
      <ToastContainer />
      <Router basename="/">
        <App />
      </Router>
    </div>
  );
};

export default AppWrapper;
