import React, { useEffect } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Prediction(props) {

  
  return (
    <div>
      <Navbar />
      <ScrollToTopOnMount />

      
  
      <section className="banner prediction">
      <div className="container">
      <div className="predictionbox">
        <div className="charts">
        <img src={require("../assets/images/graph.png")} alt="Icon" className="img-fluid" />
        <p>Charts</p>
        </div>
        <div className="w_box">
        <div className="box active">
            <img src={require("../assets/images/btc.png")} alt="Icon" className="img-fluid" />
            <p>BTC/USDT</p>
         </div>
         <div className="box">
            <img src={require("../assets/images/bnb.png")} alt="Icon" className="img-fluid" />
            <p>BNB/USDT</p>
         </div>
         <div className="box">
            <img src={require("../assets/images/eth.png")} alt="Icon" className="img-fluid" />
            <p>ETH/USDT</p>
         </div>
         </div>

         <div className="account">
         <i class="far fa-user-circle"></i>
        <p>My Account</p>
        </div>
         </div>
       </div> 
      
      <div className="bannerbg">
        <div className="container">
          <div className="boxes">
          <div className="row">
            <div className="col-lg-4 d-flex">
              <div className="in_box expired">
              <div className="head">
               <p><i class="fas fa-ban"></i>Expired</p>
               <span>#73237</span>
              </div>
              <div class="arw">
                <h5>UP</h5>
                <p>1.80x Payout</p>
              </div>
              <div className="pr_box">
                <p>Closed Price</p>
                <h5>$286.2851</h5>
                <div className="text-end">
                <p className="greenbtn">
                <img src={require("../assets/images/right_arw.png")} alt="Icon" className="img-fluid" />
                    $0.0530</p>
                </div>
                <div className="price_boxflx">
                    <p>Locked Price</p>
                    <p className="green">$286.1012</p>
                </div>
                <div className="price_boxflx">
                    <h6>Prize Pool</h6>
                    <p className="coin" >28.6666 BNB</p>
                </div>
              </div>
              <div class="arw down">
                <p>2.37x Payout</p>
                <h5>DOWN</h5>
              </div>
              </div>
            </div>
            <div className="col-lg-4 d-flex">
            <div className="in_box live">
              <div className="head">
               <p>
               <i class="fas fa-play-circle"></i>
                Live</p>
               <span>#73237</span>
              </div>
              <div class="progress">
                 <div class="progress-bar" role="progressbar" style={{width: "25%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
              <div class="arw">
                <h5>UP</h5>
                <p>1.80x Payout</p>
              </div>
              <div className="pr_box">
                <p>Closed Price</p>
                <h5>$286.2851</h5>
                <div className="text-end">
                <p className="greenbtn">
                <img src={require("../assets/images/right_arw.png")} alt="Icon" className="img-fluid" />
                    $0.0530</p>
                </div>
                <div className="price_boxflx">
                    <p>Locked Price</p>
                    <p className="green">$286.1012</p>
                </div>
                <div className="price_boxflx">
                    <h6>Prize Pool</h6>
                    <p className="coin" >28.6666 BNB</p>
                </div>
              </div>
              <div class="arw down">
                <p>2.37x Payout</p>
                <h5>DOWN</h5>
              </div>
              </div>
            </div>
            <div className="col-lg-4 d-flex">
            <div className="in_box">
              <div className="head">
               <p>
               <i class="far fa-clock"></i>
                Next</p>
               <span>#73237</span>
              </div>
              <div class="arw">
                <h5>UP</h5>
                <p>1.80x Payout</p>
              </div>
              <div className="pr_box">
                <p>Closed Price</p>
                <h5>$286.2851</h5>
                <div className="text-end">
                <p className="greenbtn">
                <img src={require("../assets/images/right_arw.png")} alt="Icon" className="img-fluid" />
                    $0.0530</p>
                </div>
                <div className="price_boxflx">
                    <p>Locked Price</p>
                    <p className="green">$286.1012</p>
                </div>
                <div className="price_boxflx">
                    <h6>Prize Pool</h6>
                    <p className="coin" >28.6666 BNB</p>
                </div>
              </div>
              <div class="arw down">
                <p>2.37x Payout</p>
                <h5>DOWN</h5>
              </div>
              </div>
            </div>
          </div>
          </div>
        </div>
       </div>
      </section>
   





      <Footer />
    </div>
  );
}
