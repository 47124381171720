import React, { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { Link } from "react-router-dom";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { getprovider } from "../pages/seperate/provider";
import Web3 from "web3";
import LotterytokenABI from "../ABI/Lotterytoken.json";
import config from "../lib/config/config";
import LotteryABI from "../ABI/Lottery.json";
import ConnectWallet from "../pages/seperate/Connect-Wallet";
import { getCurAddr, updatePrize } from "../actions/User";
import moment from "moment";
import "../App.css";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import { toast } from "react-toastify";
// toast.configure();

let toasterOption = config.toasterOption;
// import Navbar from "../components/Navbar.js";
// // Countdown Timer
// import Countdown, { zeroPad } from "react-countdown";
// import { Link } from "react-scroll";
// import Sidebar from "../components/sidebar.js";
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // return null;
}

export default function Home(props) {
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [provider, setprovider] = React.useState();
  const [address, setaddress] = React.useState();
  const [TokenBalance, Set_TokenBalance] = useState(0);
  const [currentLottery, SetcurrentLottery] = React.useState("");
  const [UserAccountAddr, Set_UserAccountAddr] = React.useState("");
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [WalletConnected, Set_WalletConnected] = React.useState("false");
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = React.useState("");
  const [allHist, SetallHist] = React.useState([]);
  const [Endlotterytime, SetEndlotteryTime] = React.useState(null);
  const [StartlotteryTime, SetStartlotteryTime] = React.useState(new Date());
  const [timerStart, setTimerStart] = React.useState(0);
  const [lotteryPrice, Set_lotteryPrice] = React.useState("");
  const [lotteryPrizeAmount, SetlotteryPrizeAmount] = React.useState(0);
  const [userTicketCount, SetuserTicketCount] = React.useState("");
  const [rewardsBreakdown, SetrewardsBreakdown] = React.useState("");
  const [historyId, SethistoryId] = React.useState(0);
  const [hisEndlotterytime, SethisEndlotteryTime] = React.useState(new Date());
  const [hisStartlotteryTime, SethisStartlotteryTime] = React.useState();
  const [hislotteryPrice, Set_hislotteryPrice] = React.useState("");
  const [hislotteryPrizeAmount, SethislotteryPrizeAmount] = React.useState(0);
  const [hisrewardsBreakdown, SethisrewardsBreakdown] = React.useState("");
  const [hisuserTicketCount, SethisuserTicketCount] = React.useState("");
  const [finalNumber, SetfinalNumber] = React.useState([]);
  const [finalNumber1, SetfinalNumber1] = React.useState([]);
  const [lotteryAmt, Set_lotteryAmt] = React.useState("");
  const [ValidateError, Set_ValidateError] = React.useState({});
  const [Allowance, setAllowance] = React.useState();
  const [ApproveCallStatus, Set__ApproveCallStatus] = React.useState("init");
  const [YouWillGet, Set_YouWillGet] = React.useState(0);
  const [loader, setloader] = React.useState(false);
  const [Ticid, SetTicid] = React.useState("");
  const [currentWinningNumber, SetcurrentWinningNumber] = React.useState([]);
  const [currentWinningNumberId, SetcurrentWinningNumberId] = React.useState(
    []
  );

  const [TicfinalNumber, SetTicfinalNumber] = React.useState([]);
  const [claim, setclaim] = React.useState();
  const [outcss, setoutcss] = React.useState("block");
  const [incss, setincss] = React.useState("none");
  const [TicStartlotteryTime, SetTicStartlotteryTime] = React.useState(
    new Date()
  );
  const [TicEndlotteryTime, SetTicEndlotteryTime] = React.useState();
  const [TiclotteryPrice, Set_TiclotteryPrice] = React.useState("");
  const [TiclotteryPrizeAmount, SetTiclotteryPrizeAmount] = React.useState("");
  const [TicrewardsBreakdown, SetTicrewardsBreakdown] = React.useState("");
  const [TicuserTicketCount, SetTicuserTicketCount] = React.useState("");
  const [discount, Setdiscount] = React.useState(0);
  const [prizePot, SetprizePot] = React.useState(0);
  const [reload, Setreload] = React.useState(true);

  const [totalval, settotalval] = React.useState(0);
  const [dis, setdis] = React.useState(0);

  // Countdown Timer
  //   const currentDate = new Date();
  //   const year =
  //     currentDate.getMonth() === 11 && currentDate.getDate() > 23
  //       ? currentDate.getFullYear() + 1
  //       : currentDate.getFullYear();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  const renderer = ({ days, hours, minutes, seconds }) => {
    // console.log(days, hours, minutes, seconds, "reload");
    if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {
      // window.location.reload()
      Setreload(false);
    }
    // console.log(reload, "reload");

    return (
      <div className="timer_panel">
        <span>
          <span className="timer_time">{zeroPad(days)}</span>
          <span className="timer_label">Days</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(hours)}</span>
          <span className="timer_label">Hours</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(minutes)}</span>
          <span className="timer_label">Minutes</span>
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(seconds)}</span>
          <span className="timer_label">Seconds</span>
        </span>
      </div>
    );
  };

  const AfterWalletConnected = async () => {
    var currAddr = await getCurAddr();
    if (currAddr) {
      getTokenbal();
    }
  };
  useEffect(()=>{
    getmyprovider();
  },[])
  useEffect(() => {
    if (provider && address) {
      getTokenbal();
      getLotteryDet();
      getallownce();
      getupdatePrize();
    }
  }, [provider, address]);



  async function getupdatePrize() {
    const result = await updatePrize();
    console.log(result, "lotteryPrizeAmount");
    var AEDprize = result.result.result[0]?.AEDprize;
    console.log(result.result.result[0]?.AEDprize, "kkkkkk");
    SetprizePot(AEDprize);
  }

  async function getallownce() {
    // var {provider, address} = await getprovider();
    if (provider) {
      let web3 = new Web3(provider);
      // let currAddrALL = await web3.eth.getAccounts();
      let currAddr = address;
      let Tokencontract = new web3.eth.Contract(
        LotterytokenABI,
        config.lotteryToken
      );

      let viewTokenDetails = await Tokencontract.methods
        .allowance(currAddr, config.lottery)
        .call();
      setAllowance(viewTokenDetails);
    }
  }

  async function getmyprovider() {
    var { provider, address } = await getprovider();
    setprovider(provider);
    setaddress(address);
  }

  async function getTokenbal() {
    try {
      if (provider) {
        var web3 = new Web3(provider);
        const currAddr = address;

        console.log(
          currAddr,
          "currAddrcurrAddrcurrAddrcurrAddrcurrAddrcurrAddr"
        );
        var CoursetroContract = new web3.eth.Contract(
          LotterytokenABI,
          config.lotteryToken
        );
        var tokenBal = await CoursetroContract.methods
          .balanceOf(currAddr)
          .call();

        var tokenBalance = tokenBal / config.decimalvalues;

        console.log(tokenBalance, "saran");

        Set_TokenBalance(tokenBalance);
      }
    } catch (err) {
      console.log("errror123:", err);
    }
  }

  async function getLotteryDet() {
    try {
      // var {provider, address} = await getprovider();
      if (provider) {
        var web3 = new Web3(provider);
        // var currAddrALL = await web3.eth.getAccounts();
        var currAddr = address;
        var LotteryContract = new web3.eth.Contract(LotteryABI, config.lottery);
        var currentLotteryId = await LotteryContract.methods
          .currentLotteryId()
          .call();
        console.log(currentLotteryId, "lotteryID");
        SetcurrentLottery(currentLotteryId);
        await historychange("id", currentLotteryId);

        var viewLotteryDetails = await LotteryContract.methods
          .viewUserInfoForLotteryId(currAddr, currentLotteryId, 0, 100000)
          .call();

        console.log(
          viewLotteryDetails,
          "viewLotteryDetailsviewLotteryDetailsviewLotteryDetailsviewLotteryDetails"
        );

        var getUserLottery = await LotteryContract.methods
          .getUserLottery(currAddr)
          .call();

        console.log(getUserLottery, "getUserLottery");

        const UniqueLott = {};
        getUserLottery.forEach(function (x) {
          if (x != currentLotteryId) UniqueLott[x] = (UniqueLott[x] || 0) + 1;
        });
        // console.log(UniqueLott,"UniqueLott");
        var histArray = [];

        for (const property in UniqueLott) {
          var obj = {};
          var ticketid = property;
          console.log(ticketid, "ticketid");
          var count = UniqueLott[property];
          var hisTick = await LotteryContract.methods
            .viewLottery(ticketid)
            .call();

          console.log(hisTick, "hisTick");
          var enddate = new Date(hisTick.endTime * 1000);

          obj = { ticketid: ticketid, count: count, enddate: enddate };

          histArray.push(obj);
        }

        SetallHist(histArray);

        console.log(UniqueLott, "UniqueLottUniqueLottUniqueLott");
        var viewLotteryId = await LotteryContract.methods
          .viewLottery(currentLotteryId)
          .call();

        console.log(
          viewLotteryId,
          "viewLotteryIdviewLotteryIdviewLotteryIdviewLotteryId"
        );

        var amountPrize =
          viewLotteryId.amountCollectedInCake / 1000000000000000000;

        var minusTruesaryFees = (amountPrize * 30) / 100;

        var finalPrizeAmount = amountPrize - minusTruesaryFees;

        var startdate = new Date(viewLotteryId.startTime * 1000);
        var enddate = new Date(viewLotteryId.endTime * 1000);
        SetEndlotteryTime(Date.parse(new Date(enddate)));

        // var adddays = new Date(lastregister);
        // adddays.setDate(adddays.getDate() + 5);
        // var timestamp = Date.parse(new Date(adddays));

        SetStartlotteryTime(startdate);
        setTimerStart(1);
        Set_lotteryPrice(viewLotteryId.priceTicketInCake / 1000000000000000000);
        SetlotteryPrizeAmount(finalPrizeAmount);
        SetuserTicketCount(viewLotteryDetails[3]);
        SetrewardsBreakdown(viewLotteryId.rewardsBreakdown);
        console.log(viewLotteryId.rewardsBreakdown, "rewards");
        console.log(startdate, "starttime");
        console.log(Endlotterytime, "endtime");
      }
    } catch (err) {
      console.log(err, "err");
    }
  }

  async function historychange(type, e) {
    try {
      var currentLotteryHis = parseInt(currentLottery);
      var id = currentLotteryHis;
      if (type == "curr") {
        var id =
          e.target.value &&
          e.target.value <= currentLotteryHis &&
          e.target.value != 0 &&
          e.target.value != ""
            ? e.target.value
            : currentLotteryHis;
      } else if (type == "next") {
        var next = parseInt(historyId) + 1;
        var id =
          next <= currentLotteryHis && next != 0 && next != ""
            ? next
            : currentLotteryHis;
      } else if (type == "prev") {
        var next = parseInt(historyId) - 1;
        var id =
          next <= currentLotteryHis && next != 0 && next != ""
            ? next
            : currentLotteryHis;
      } else if (type == "id") {
        var id = parseInt(e);
      }
      // var {provider, address} = await getprovider();

      if (provider) {
        var web3 = new Web3(provider);
        // var currAddr = await web3.eth.getAccounts();
        var currAddr = address;
        var LotteryContract = new web3.eth.Contract(LotteryABI, config.lottery);
        if (id >= 0) {
          SethistoryId(id);

          var viewLotteryId = await LotteryContract.methods
            .viewLottery(id)
            .call();

          var viewLotteryDetails = await LotteryContract.methods
            .viewUserInfoForLotteryId(currAddr, id, 0, 100000)
            .call();

          var startdate = new Date(viewLotteryId.startTime * 1000);
          var enddate = new Date(viewLotteryId.endTime * 1000);

          var amountPrize =
            viewLotteryId.amountCollectedInCake / 1000000000000000000;

          var minusTruesaryFees = (amountPrize * 30) / 100;

          var finalPrizeAmount = amountPrize - minusTruesaryFees;

          SethisStartlotteryTime(startdate);
          SethisEndlotteryTime(enddate);
          Set_hislotteryPrice(
            viewLotteryId.priceTicketInCake / 1000000000000000000
          );
          SethislotteryPrizeAmount(finalPrizeAmount);
          SethisuserTicketCount(viewLotteryDetails[3]);
          SethisrewardsBreakdown(viewLotteryId.rewardsBreakdown);
          var digits = viewLotteryId.finalNumber.toString().split("");
          var realDigits = digits.map(Number);
          console.log(realDigits, "realDigitsrealDigitsrealDigitsrealDigits");

          SetfinalNumber1(realDigits);
        }
      }
    } catch (err) {}
  }

  const inputChange = async (e) => {
    console.log("inputChange");
    if (
      e &&
      e.target &&
      typeof e.target.value != "undefined" &&
      e.target.name
    ) {
      var value = e.target.value;
      switch (e.target.name) {
        case "lotteryAmt":
          if (value != "" && isNaN(value) == false && value > 0) {
            Set_lotteryAmt(value);
            ValidateError.lotteryAmt = "";
            let calc = await PriceCalculate({ lotteryAmt: value });
            Set_ValidateError(ValidateError);
            let estimate = calc * config.decimalvalues;
            let estimateamount = await convert(estimate);
            let myallowance = parseFloat(Allowance);
            console.log(myallowance, "--------631");
            // myallowance = await convert(myallowance);
            // myallowance = parseFloat(myallowance);
            console.log(
              estimate,
              typeof estimateamount,
              myallowance,
              "allowance",
              estimateamount < myallowance
            );
            // console.log(estimate, myallowance, "estimate , myallowance");
            // if (estimate < myallowance) {
            //   Set__ApproveCallStatus("done");
            // }
          } else {
            ValidateError.lotteryAmt = "Enter valid price";
            Set_ValidateError(ValidateError);
            Set_lotteryAmt(value);
          }
          break;
      }
      // window.$('#Validation_PlaceABid').click();
      console.log(value, "targetvalue");
      if (value > 100) {
        toast.warn("Please enter equal or below 100", toasterOption);
        Set_lotteryAmt("");
      }
    }
  };

  const PriceCalculate = async (data = {}) => {
    var price =
      typeof data.lotteryAmt != "undefined" ? data.lotteryAmt : lotteryAmt;
    var showtotal = lotteryPrice * price;

    var web3 = new Web3(provider);
    var LotteryContract = new web3.eth.Contract(LotteryABI, config.lottery);
    console.log(lotteryPrice, price, "RajTest");
    var amount =
      (await LotteryContract.methods
        .calculateTotalPriceForBulkTickets(2000, lotteryPrice * 1e5, price)
        .call()) / 1e5;

    var percent = ((showtotal - parseFloat(amount)) / showtotal) * 100;

    setdis(percent);
    settotalval(showtotal);
    Setdiscount(showtotal - parseFloat(amount));
    Set_YouWillGet(parseFloat(amount));
    return amount;
  };
  async function convert(n) {
    try {
      var sign = +n < 0 ? "-" : "",
        toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead, decimal, pow] = n
        .toString()
        .replace(/^-/, "")
        .replace(/^([0-9]+)(e.*)/, "$1.$2")
        .split(/e|\./);
      return +pow < 0
        ? sign +
            "0." +
            "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
            lead +
            decimal
        : sign +
            lead +
            (+pow >= decimal.length
              ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
              : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
    } catch (err) {
      return 0;
    }
  }

  const onKeyUp = (e) => {
    var charCode = e.keyCode;
    if ((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106)) {
      var ValidateError = {};
      Set_ValidateError(ValidateError);
    }
    // } else {
    //   var ValidateError = {};
    //   ValidateError.lotteryAmt = 'Quantity must be a number';
    //   Set_ValidateError(ValidateError);
    //   return false;
    // }
    if (e.target.value > 100) {
      toast.warn("Please enter Equal or below 100 Quantity");
      Set_lotteryAmt("");

      return false;
    }
    if (e.target.value.length == 4) {
      toast.warn("Please enter Equal or below 100 Quantity");
      Set_lotteryAmt("");

      return false;
    }
  };

  async function approveNow(e) {
    try {
      console.log(
        TokenBalance,
        YouWillGet,
        lotteryAmt,
        "TokenBalance < YouWillGet"
      );
      var intValue = lotteryAmt.indexOf(".");
      if (!lotteryAmt) {
        toast.error("Ticket count must be required", toasterOption);
        return false;
      } else if (lotteryAmt <= 0) {
        toast.error("Ticket must be greater than 0", toasterOption);
        return false;
      } else if (intValue != -1) {
        toast.error("Invalid ticket count", toasterOption);
        return false;
      }
      setloader(true);
      // var {provider, address} = await getprovider();
      console.log(provider,"provider")
      if (provider) {
        var web3 = new Web3(provider);
        var currAddr = address;
        var Refitplus = new web3.eth.Contract(
          LotterytokenABI,
          config.lotteryToken
        );
        var sendAmt = Math.ceil(YouWillGet) * config.decimalvalues;
          // console.log(sendAmt,"sendAmtsendAmtsendAmt")
        var finamount = await convert(sendAmt);
        if (TokenBalance < YouWillGet) {
          toast.error("Insufficent Balance", toasterOption);
          setloader(false);
          return false;
        }
  
        Set__ApproveCallStatus("processing");

        console.log(sendAmt, finamount.toString(),finamount,"sendAmt")

        await Refitplus.methods
          .approve(config.lottery, finamount.toString())
          .send({ from: address })
          .then(async (result) => {
            if (result && result != "") {
              Set__ApproveCallStatus("done");
              toast.success("Token Approved Successfully", toasterOption);
              setloader(false);
            }
          })
          .catch((error) => {
            toast.error("Approve failed", toasterOption);
            setloader(false);
          });
      }
    } catch (err) {
      console.log("err", err);
      setloader(false);
    }
  }

  // function isFloat(value) {
  //   if (
  //     typeof value === "number" &&
  //     !Number.isNaN(value) &&
  //     !Number.isInteger(value)
  //   ) {
  //     return true;
  //   }

  //   return false;
  // }

  const validPositive = (e) => {
    console.log(
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value),
      "valllluee"
    );
    if (
      new RegExp(`^\\d*(\\.\\d{0,8})?$`).test(e.target.value) ||
      (e.target.value = "")
    ) {
      e.preventDefault();
    }
  };

  async function BuyNow(e) {
    try {
      // var lamt = parseFloat(lotteryAmt);
      console.log(lotteryAmt.indexOf("."), lotteryAmt, "lotteryAmt");
      var intValue = lotteryAmt.indexOf(".");
      if (!lotteryAmt) {
        toast.error("Ticket count must be required", toasterOption);
        return false;
      } else if (lotteryAmt <= 0) {
        toast.error("Ticket must be greater than 0", toasterOption);
        return false;
      } else if (intValue != -1) {
        toast.error("Invalid ticket count", toasterOption);
        return false;
      }
      if (TokenBalance < YouWillGet) {
        toast.error("Insufficent Balance", toasterOption);
        setloader(false);
        return false;
      }
      setloader(true);
      // var {provider, address} = await getprovider();
      if (provider) {
        var web3 = new Web3(provider);
        var currAddr = address;
        var LotteryContract = new web3.eth.Contract(LotteryABI, config.lottery);
        var sendAmt = lotteryAmt * config.decimalvalues;

        var CurrencyLotterId = await LotteryContract.methods
          .currentLotteryId()
          .call();
        console.log(config.lottery, "config.lottery", CurrencyLotterId);
        var ticketArray = [];

        for (var i = 0; i < lotteryAmt; i++) {
          var randomNo = Math.floor(Math.random() * (1999999 - 1000000 + 1));
          var randomNo =
            parseFloat(randomNo.toString().split("").reverse().join("")) *
            Math.sign(randomNo);
          var final = randomNo + 1000000;

          ticketArray.push(final);
        }
        console.log(
          CurrencyLotterId,
          ticketArray,
          "Raj",
          Accounts,
          "saran",
          ">>>>>>>>>>>>>>>>>>>>>>>>>>>>>"
          
        );
        await LotteryContract.methods
          .buyTickets(CurrencyLotterId, ticketArray)
          .send({ from: address })
          .then(async (result) => {
            toast.success("Ticket Purchased Successfully", toasterOption);
            setloader(false);
            window.location.reload();
          })
          .catch((error) => {
            console.log("buyerror:", error);
            toast.error("Ticket Purchased failed", toasterOption);
            setloader(false);
            window.location.reload();
          });
      }
    } catch (err) {
      console.log("222", err);
      setloader(false);
      window.location.reload();
    }
  }

  async function viewticketdetails(id = "") {
    // var {provider, address} = await getprovider();
    getclaimstatus(id);
    console.log(id, "idididididididiidiididdididi");
    if (provider) {
      var web3 = new Web3(provider);
      // var currAddrALL = await web3.eth.getAccounts();
      var currAddr = address;
      var LotteryContract = new web3.eth.Contract(LotteryABI, config.lottery);
      var tickid = Ticid;
      if (id != "") tickid = id;

      SetTicid(tickid);

      var lotDetails = await LotteryContract.methods
        .viewUserInfoForLotteryId(currAddr, tickid, 0, 1000000)
        .call();

      console.log(
        lotDetails,
        "lotDetailslotDetailslotDetailslotDetailslotDetails"
      );

      SetcurrentWinningNumber(lotDetails[1]);
      SetcurrentWinningNumberId(lotDetails[0]);

      var viewLotteryId = await LotteryContract.methods
        .viewLottery(tickid)
        .call();
      console.log(viewLotteryId, "viewLotteryId");
      var digits = viewLotteryId.finalNumber.toString().split("");
      console.log(digits, "digists");
      var realDigits = digits.map(Number);
      console.log(realDigits, "realDigits");
      SetfinalNumber(realDigits);
      SetTicfinalNumber(realDigits);
      console.log(TicfinalNumber, "TicfinalNumber");
    }
  }

  async function claimRewards() {
    // var {provider, address} = await getprovider();
    if (provider) {
      var bracArray = [];
      var bracArrayTic = [];

      for (var i = 0; i < currentWinningNumber.length; i++) {
        var data = currentWinningNumber[i];
        var digits = data.toString().split("");
        var realDigits = digits.map(Number);
        var match = 10;

        if (
          realDigits[6] == TicfinalNumber[6] &&
          realDigits[5] == TicfinalNumber[5] &&
          realDigits[4] == TicfinalNumber[4] &&
          realDigits[3] == TicfinalNumber[3] &&
          realDigits[2] == TicfinalNumber[2] &&
          realDigits[1] == TicfinalNumber[1]
        ) {
          match = 5;
        } else if (
          realDigits[6] == TicfinalNumber[6] &&
          realDigits[5] == TicfinalNumber[5] &&
          realDigits[4] == TicfinalNumber[4] &&
          realDigits[3] == TicfinalNumber[3] &&
          realDigits[2] == TicfinalNumber[2]
        ) {
          match = 4;
        } else if (
          realDigits[6] == TicfinalNumber[6] &&
          realDigits[5] == TicfinalNumber[5] &&
          realDigits[4] == TicfinalNumber[4] &&
          realDigits[3] == TicfinalNumber[3]
        ) {
          match = 3;
        } else if (
          realDigits[6] == TicfinalNumber[6] &&
          realDigits[5] == TicfinalNumber[5] &&
          realDigits[4] == TicfinalNumber[4]
        ) {
          match = 2;
        } else if (
          realDigits[6] == TicfinalNumber[6] &&
          realDigits[5] == TicfinalNumber[5]
        ) {
          match = 1;
        } else if (realDigits[6] == TicfinalNumber[6]) {
          match = 0;
        }
        console.log(match, "matchmatchmatchmatch");
        if (match != 10) {
          bracArrayTic.push(currentWinningNumberId[i]);
          bracArray.push(match);
        }
      }
      setloader(true);

      var web3 = new Web3(provider);
      // var currAddrALL = await web3.eth.getAccounts();
      var currAddr = address;
      var LotteryContract = new web3.eth.Contract(LotteryABI, config.lottery);

      if (bracArrayTic.length > 0) {
        console.log(bracArrayTic, "saran");
        var lotDetailsnewWithdraw = await LotteryContract.methods
          .viewNumbersAndStatusesForTicketIds(bracArrayTic)
          .call();

        console.log(
          lotDetailsnewWithdraw,
          "lotDetailsnewWithdrawlotDetailsnewWithdrawlotDetailsnewWithdrawlotDetailsnewWithdrawlotDetailsnewWithdraw",
          Ticid,
          bracArrayTic,
          bracArray
        );

        if (!lotDetailsnewWithdraw[1][0]) {
          try {
            var lotDetailsnewWithdraw = await LotteryContract.methods
              .claimTickets(Ticid, bracArrayTic, bracArray)
              .send({ from: currAddr })
              .then((result) => {
                localStorage.setItem("claim", currAddr);
                localStorage.setItem("round", Ticid);
                toast.success("Rewards claimed successfully", toasterOption);
                window.location.reload();
                setloader(false);
              })
              .catch((error) => {
                toast.warn("User Rejected Rewards", toasterOption);
                localStorage.removeItem("claim");
                setloader(false);
              });
          } catch {
            localStorage.removeItem("claim");
            setloader(false);
          }
        } else {
          toast.warn("Rewards Already claimed", toasterOption);
          setloader(false);
        }
      } else {
        toast.warn("There is no winning ticket to claim", toasterOption);
        setloader(false);
      }
    }
  }

  async function getclaimstatus(_id) {
    // var {provider, address} = await getprovider();
    if (provider) {
      var web3 = new Web3(provider);
      // var currAddrALL = await web3.eth.getAccounts();
      var currAddr = address;
      if (localStorage.getItem("claim")) {
        var address = localStorage.getItem("claim");
        var Round = localStorage.getItem("round");
        console.log(address, Round, "nhjbkjhbh", Ticid);
        if (address === currAddr && _id === Round) {
          setclaim(false);
        } else {
          setclaim(true);
        }
      } else {
        setclaim(true);
      }
    }
  }

  async function enableIn() {
    setoutcss("block");
    setincss("none");
  }
  async function enableOut(id) {
    setoutcss("none");
    setincss("block");
    // var {provider, address} = await getprovider();
    if (provider) {
      var web3 = new Web3(provider);
      // var currAddrALL = await web3.eth.getAccounts();
      var currAddr = address;
      var LotteryContract = new web3.eth.Contract(LotteryABI, config.lottery);

      var viewLotteryDetails = await LotteryContract.methods
        .viewUserInfoForLotteryId(currAddr, id, 0, 100000)
        .call();

      var viewLotteryId = await LotteryContract.methods.viewLottery(id).call();

      var startdate = new Date(viewLotteryId.startTime * 1000);
      var enddate = new Date(viewLotteryId.endTime * 1000);

      var amountPrize =
        viewLotteryId.amountCollectedInCake / 1000000000000000000;

      var minusTruesaryFees = (amountPrize * 30) / 100;

      var finalPrizeAmount = amountPrize - minusTruesaryFees;

      SetTicStartlotteryTime(startdate);
      SetTicEndlotteryTime(enddate);
      Set_TiclotteryPrice(
        viewLotteryId.priceTicketInCake / 1000000000000000000
      );
      SetTiclotteryPrizeAmount(finalPrizeAmount);
      SetTicrewardsBreakdown(viewLotteryId.rewardsBreakdown);
      SetTicuserTicketCount(viewLotteryDetails[3]);
      var digits = viewLotteryId.finalNumber.toString().split("");
      var realDigits = digits.map(Number);

      SetTicfinalNumber(realDigits);
      console.log("issue", TicfinalNumber);
      SetTicid(id);
    }
  }
  // const [state, setState] = useState(false)
  // const Click = () => {
  //     setState(!state)
  // }
  // className={"btn-group pull-right " + (state ? 'show' : 'hidden')}

  //   // $(".primary_btn").(function(){
  //   //   $(".sidebar").addClass("menu_show");
  //   // });
  //   $(document).ready(function(){
  //     $("button").click(function(){
  //       $(".sidebar").addClass("menu_show");
  //     });
  //   });

  console.log(allHist, "allHist");
  return (
    <div>
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
      />
      <Navbar
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        handleMenu={handleMenu}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
      <ScrollToTopOnMount />

      <div
        class="modal fade buy_modal"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                Buy Tickets
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="mb-3">
                <label for="exampleInputEmail1" class="form-label">
                  <p>Buy:</p>
                  <p>
                    <span>Tickets</span>
                  </p>
                </label>
                <div className="box">
                  <input
                    type="text"
                    className="form-control primary_inp"
                    onChange={inputChange}
                    onInput={validPositive}
                    value={lotteryAmt}
                    onKeyUp={onKeyUp}
                    autoComplete="off"
                    id="lotteryAmt"
                    name="lotteryAmt"
                    onKeyDown={(evt) =>
                      ["e", "E", "+", "-", "."].includes(evt.key) &&
                      evt.preventDefault()
                    }
                  />
                  {ValidateError.lotteryAmt && (
                    <span className="text-danger d-block ps-2">
                      <br />
                      {ValidateError.lotteryAmt}
                    </span>
                  )}
                  <p>{TokenBalance} BNJR</p>
                </div>
              </div>

              <div class="flx">
                <p>Cost(BNJR)</p>
                <p>{totalval.toFixed(5)}</p>
              </div>

              <div class="flx mb-3 brdr">
                <div className="d-flex align-items-center">
                  <p>
                    <span>{dis.toFixed(2)}%</span> Bulk discount
                  </p>
                  <div class="tooltips" style={{ marginRight: "-25px" }}>
                    <span>
                      <HelpOutlineIcon />
                    </span>
                    <p class="tooltiptexts" style={{ textAlign: "left" }}>
                      Buying multiple tikets in a single transaction gives a
                      discount. The discount increaces in a linear way, up to
                      the maximum of 100 tickets:
                      <br />
                      <b>
                        <p>2 tickets : 0.05%</p>
                        <p>50 tickets : 2.45%</p>
                        <p>100 tickets : 4.97%</p>
                      </b>
                    </p>
                  </div>
                </div>

                <p>{discount > 0 && discount.toFixed(5)} BNJR</p>
              </div>
              <div class="flx">
                <p>You pay</p>
                <p>
                  <span>{YouWillGet.toFixed(5)} BNJR</span>
                </p>
              </div>
              {console.log(ApproveCallStatus, "ApproveCallStatus")}
              {ApproveCallStatus == "init" ||
              ApproveCallStatus == "processing" ? (
                <button className="btn butn" onClick={() => approveNow()} z>
                  {loader && <i class="fas fa-spinner fa-spin mr-2"></i>}
                  Approve
                </button>
              ) : (
                <button className="btn butn" onClick={() => BuyNow()} z>
                  {loader && <i class="fas fa-spinner fa-spin mr-2"></i>}
                  Buy Now
                </button>
              )}
              <p className="notes">
                "Buy Instantly" chooses random numbers, with no duplicates among
                your tickets. Prices are set before each round starts, equal to
                $5 at that time. Purchases are final.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="home">
        <section className="banner">
          <div className="bannerbg">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="img_box">
                    <img
                      src={require("../assets/images/banimg.png")}
                      alt="Icon"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <h5>The Bonjour Lottery</h5>
                  <h5>
                    <span>{lotteryPrizeAmount.toFixed(6)} </span>in Prize!
                  </h5>
                  {new Date(Endlotterytime) >= new Date() && reload ? (
                    <button
                      className="btn butn"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      BUY TICKET
                    </button>
                  ) : (
                    <button className="btn butn">Closed</button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="banner_box">
          <div className="curve"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <div className="box">
                  <div className="abv_box">
                    <div className="row">
                      <div className="col-xl-7">
                        <h2>Get your Tickets Now!</h2>
                        <h5>until withdraw</h5>
                      </div>
                      <div className="col-xl-4">
                        {Endlotterytime && timerStart == 1 && (
                          <Countdown
                            date={parseInt(Endlotterytime)}
                            renderer={renderer}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="flx">
                    <h5>Next Draw</h5>
                    <p>
                      #{currentLottery} | Draw:{" "}
                      {moment(Endlotterytime).format("LLLL")}
                    </p>
                  </div>
                  <div className="price_box">
                    <div className="row">
                      <div className="col-lg-9 col-xl-9">
                        <div className="flex mb-3">
                          {console.log(
                            prizePot,
                            lotteryPrizeAmount.toFixed(6),
                            "llll"
                          )}
                          <p>Prize Pot</p>
                          <div>
                            {console.log(0.01113 == NaN, "muvitha")}
                            <h2>{lotteryPrizeAmount.toFixed(4)} BNJR</h2>
                            <span>
                              {" "}
                              $
                              {isNaN((prizePot * lotteryPrizeAmount).toFixed(4))
                                ? 0
                                : (
                                    prizePot * lotteryPrizeAmount.toFixed(4)
                                  ).toFixed(4)}
                            </span>
                          </div>
                        </div>
                        <div className="flex">
                          <p>Your Tickets</p>
                          {new Date(Endlotterytime) >= new Date() && reload ? (
                            <button
                              className="btn butn mb-0"
                              data-bs-toggle="modal"
                              data-bs-target="#staticBackdrop"
                              style={{ marginRight: "10px", padding: "10px" }}
                            >
                              BUY TICKET
                            </button>
                          ) : (
                            <button
                              className="btn butn mb-0"
                              style={{
                                marginRight: "10px",
                                padding: "10px",
                              }}
                            >
                              Closed
                            </button>
                          )}
                          {userTicketCount > 0 && (
                            <button
                              className="btn butn mb-0 p-2 ms-2"
                              data-bs-toggle="modal"
                              data-bs-target="#viewtickets_modal"
                              onClick={() => viewticketdetails(currentLottery)}
                            >
                              View TICKET
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-3 col-xl-3 my-auto">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/cup.png")}
                            alt="Icon"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div class="accordion-item">
                      <div
                        id="flush-collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div class="accordion-body">
                          <p className="mb-0">
                            Match the winning number in the same order to share
                            prizes. Current prizes up for grabs:
                          </p>
                          {console.log(
                            prizePot *
                              (
                                (lotteryPrizeAmount * rewardsBreakdown[0]) /
                                10000
                              ).toFixed(2),
                            "kkkkk"
                          )}
                          <div className="row">
                            <div className="col-md-3">
                              <div className="inbox">
                                <p>Match first 1</p>
                                <h5>
                                  {" "}
                                  {isNaN(
                                    (
                                      (lotteryPrizeAmount *
                                        rewardsBreakdown[0]) /
                                      10000
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : (
                                        (lotteryPrizeAmount *
                                          rewardsBreakdown[0]) /
                                        10000
                                      ).toFixed(2)}{" "}
                                  BNJR
                                </h5>
                                <span>
                                  ~$
                                  {isNaN(
                                    (
                                      prizePot *
                                      ((lotteryPrizeAmount *
                                        rewardsBreakdown[0]) /
                                        10000)
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : (
                                        prizePot *
                                        ((lotteryPrizeAmount *
                                          rewardsBreakdown[0]) /
                                          10000)
                                      ).toFixed(2)}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="inbox">
                                <p>Match first 2</p>
                                <h5>
                                  {" "}
                                  {isNaN(
                                    (
                                      (lotteryPrizeAmount *
                                        rewardsBreakdown[1]) /
                                      10000
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : (
                                        (lotteryPrizeAmount *
                                          rewardsBreakdown[1]) /
                                        10000
                                      ).toFixed(2)}{" "}
                                  BNJR
                                </h5>
                                <span>
                                  ~$
                                  {isNaN(
                                    (
                                      prizePot *
                                      ((lotteryPrizeAmount *
                                        rewardsBreakdown[1]) /
                                        10000)
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : (
                                        prizePot *
                                        ((lotteryPrizeAmount *
                                          rewardsBreakdown[1]) /
                                          10000)
                                      ).toFixed(2)}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="inbox">
                                <p>Match first 3</p>

                                <h5>
                                  {" "}
                                  {isNaN(
                                    (
                                      (lotteryPrizeAmount *
                                        rewardsBreakdown[2]) /
                                      10000
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : (
                                        (lotteryPrizeAmount *
                                          rewardsBreakdown[2]) /
                                        10000
                                      ).toFixed(2)}{" "}
                                  BNJR
                                </h5>
                                <span>
                                  ~$
                                  {isNaN(
                                    (
                                      prizePot *
                                      ((lotteryPrizeAmount *
                                        rewardsBreakdown[2]) /
                                        10000)
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : (
                                        prizePot *
                                        ((lotteryPrizeAmount *
                                          rewardsBreakdown[2]) /
                                          10000)
                                      ).toFixed(2)}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="inbox">
                                <p>Match first 4</p>
                                <h5>
                                  {" "}
                                  {isNaN(
                                    (
                                      (lotteryPrizeAmount *
                                        rewardsBreakdown[3]) /
                                      10000
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : (
                                        (lotteryPrizeAmount *
                                          rewardsBreakdown[3]) /
                                        10000
                                      ).toFixed(2)}{" "}
                                  BNJR
                                </h5>
                                <span>
                                  ~$
                                  {isNaN(
                                    (
                                      prizePot *
                                      ((lotteryPrizeAmount *
                                        rewardsBreakdown[3]) /
                                        10000)
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : (
                                        prizePot *
                                        ((lotteryPrizeAmount *
                                          rewardsBreakdown[3]) /
                                          10000)
                                      ).toFixed(2)}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="inbox">
                                <p>Match first 5</p>
                                <h5>
                                  {" "}
                                  {isNaN(
                                    (
                                      (lotteryPrizeAmount *
                                        rewardsBreakdown[4]) /
                                      10000
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : (
                                        (lotteryPrizeAmount *
                                          rewardsBreakdown[4]) /
                                        10000
                                      ).toFixed(2)}{" "}
                                  BNJR
                                </h5>
                                <span>
                                  ~$
                                  {isNaN(
                                    (
                                      prizePot *
                                      ((lotteryPrizeAmount *
                                        rewardsBreakdown[4]) /
                                        10000)
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : (
                                        prizePot *
                                        ((lotteryPrizeAmount *
                                          rewardsBreakdown[4]) /
                                          10000)
                                      ).toFixed(2)}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="inbox">
                                <p>Match all 6</p>
                                <h5>
                                  {isNaN(
                                    (
                                      (lotteryPrizeAmount *
                                        rewardsBreakdown[5]) /
                                      10000
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : (
                                        (lotteryPrizeAmount *
                                          rewardsBreakdown[5]) /
                                        10000
                                      ).toFixed(2)}{" "}
                                  BNJR
                                </h5>
                                <span>
                                  ~$
                                  {isNaN(
                                    (
                                      prizePot *
                                      ((lotteryPrizeAmount *
                                        rewardsBreakdown[5]) /
                                        10000)
                                    ).toFixed(2)
                                  )
                                    ? 0
                                    : (
                                        prizePot *
                                        ((lotteryPrizeAmount *
                                          rewardsBreakdown[5]) /
                                          10000)
                                      ).toFixed(2)}
                                </span>
                              </div>
                            </div>
                            {/* <div className="col-md-3">
                              <div className="inbox">
                                <p>Burn</p>
                                <h5>4,548 BNJR</h5>
                                <span>~$17,725</span>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <h2 class="accordion-header" id="flush-headingOne">
                        <button
                          class="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="true"
                          aria-controls="flush-collapseOne"
                        >
                          <span className="hide">Hide</span>
                          <span className="details">Details</span>
                        </button>
                      </h2>
                    </div>
                  </div>
                </div>
                {/* <div className="connect_box">
                  <p>Connect your wallet to check if you've won!</p>
                  <button className="btn butn">
                    <img
                      src={require("../assets/images/wallet.png")}
                      alt="Icon"
                      className="img-fluid"
                    />
                    CONNECT WALLET
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        <section className="finished_rounds banner_box m-0">
          <div className="container">
            <div class="row">
              <div className="col-lg-8 mx-auto">
                <div class="head">
                  <h2 className="h2tags">Finished Rounds</h2>
                  <nav className="d-flex">
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                      <button
                        class="nav-link active"
                        id="nav-one-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-one"
                        type="button"
                        role="tab"
                        aria-controls="nav-one"
                        aria-selected="true"
                      >
                        All History
                      </button>
                      <button
                        class="nav-link"
                        id="nav-two-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-two"
                        type="button"
                        role="tab"
                        aria-controls="nav-two"
                        aria-selected="false"
                      >
                        Your History
                      </button>
                    </div>
                  </nav>
                </div>
                <div class="tab-content" id="nav-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="nav-one"
                    role="tabpanel"
                    aria-labelledby="nav-one-tab"
                  >
                    <div className="box m-0">
                      <div className="a_box">
                        <div className="row">
                          <div className="col-sm-8">
                            <h6>
                              Round <span>{historyId}</span>{" "}
                            </h6>
                            <p>{moment(hisEndlotterytime).format("LLLL")}</p>
                          </div>
                          <div className="col-sm-4">
                            <div className="arrows">
                              <div className="arrow active">
                                <img
                                  src={require("../assets/images/left_arw.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                  onClick={() => historychange("prev")}
                                />
                              </div>
                              <div className="arrow">
                                <img
                                  src={require("../assets/images/right_arw.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                  onClick={() => historychange("next")}
                                />
                              </div>
                              {/* <div className="arrow">
                                <img
                                  src={require("../assets/images/next_arw.png")}
                                  alt="Icon"
                                  className="img-fluid ms-3 "
                                />
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flx">
                        <h5>Winning Number</h5>
                        <div className="numbers">
                          <div className="round round1">{finalNumber1[6]}</div>
                          <div className="round round2">{finalNumber1[5]}</div>
                          <div className="round round3">{finalNumber1[4]}</div>
                          <div className="round round4">{finalNumber1[3]}</div>
                          <div className="round round5">{finalNumber1[2]}</div>
                          <div className="round round6">{finalNumber1[1]}</div>
                        </div>
                      </div>

                      <div
                        class="accordion accordion-flush"
                        id="accordionFlushExample"
                      >
                        <div class="accordion-item">
                          <div
                            id="flush-collapsetwo"
                            class="accordion-collapse collapse"
                            aria-labelledby="flush-headingtwo"
                            data-bs-parent="#accordionFlushExample"
                          >
                            <div class="accordion-body">
                              <div className="row">
                                <div className="col-xxl-3">
                                  <div className="left_box">
                                    <div className="price_box">
                                      <div class="flex mb-3">
                                        <p>Prize Pot</p>

                                        <div>
                                          <h2>
                                            {hislotteryPrizeAmount.toFixed(4)}{" "}
                                            BNJR
                                          </h2>
                                          <span>
                                            $
                                            {isNaN(
                                              (
                                                prizePot * hislotteryPrizeAmount
                                              ).toFixed(4)
                                            )
                                              ? 0
                                              : (
                                                  prizePot *
                                                  hislotteryPrizeAmount.toFixed(
                                                    4
                                                  )
                                                ).toFixed(4)}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div className="total">
                                      <p>Total players this round:</p>
                                      <p>153</p>
                                    </div> */}
                                  </div>
                                </div>
                                <div className="col-xxl-9">
                                  <p className="mb-0">
                                    Match the winning number in the same order
                                    to share prizes. Current prizes up for
                                    grabs:
                                  </p>
                                  <div className="row">
                                    <div className="col-md-3">
                                      <div className="inbox">
                                        <p>Match first 1</p>
                                        <h5>
                                          {isNaN(
                                            (
                                              (hislotteryPrizeAmount *
                                                hisrewardsBreakdown[0]) /
                                              10000
                                            ).toFixed(2)
                                          )
                                            ? 0
                                            : (
                                                (hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[0]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                          BNJR
                                        </h5>
                                        <span>
                                          ~$
                                          {isNaN(
                                            (
                                              prizePot *
                                              ((hislotteryPrizeAmount *
                                                hisrewardsBreakdown[0]) /
                                                10000)
                                            ).toFixed(2)
                                          )
                                            ? 0
                                            : (
                                                prizePot *
                                                ((hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[0]) /
                                                  10000)
                                              ).toFixed(2)}
                                        </span>
                                        {/* <span>2.31 BNJR each</span>
                                        <span> 148 Winning Tickets</span> */}
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="inbox">
                                        <p>Match first 2</p>

                                        <h5>
                                          {isNaN(
                                            (
                                              (hislotteryPrizeAmount *
                                                hisrewardsBreakdown[1]) /
                                              10000
                                            ).toFixed(2)
                                          )
                                            ? 0
                                            : (
                                                (hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[1]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                          BNJR
                                        </h5>
                                        <span>
                                          ~$
                                          {isNaN(
                                            (
                                              prizePot *
                                              ((hislotteryPrizeAmount *
                                                hisrewardsBreakdown[1]) /
                                                10000)
                                            ).toFixed(2)
                                          )
                                            ? 0
                                            : (
                                                prizePot *
                                                ((hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[1]) /
                                                  10000)
                                              ).toFixed(2)}
                                        </span>
                                        {/* <span>34.31 BNJR each</span>
                                        <span> 15 Winning Tickets</span> */}
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="inbox">
                                        <p>Match first 3</p>
                                        <h5>
                                          {isNaN(
                                            (
                                              (hislotteryPrizeAmount *
                                                hisrewardsBreakdown[2]) /
                                              10000
                                            ).toFixed(2)
                                          )
                                            ? 0
                                            : (
                                                (hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[2]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                          BNJR
                                        </h5>
                                        <span>
                                          ~$
                                          {isNaN(
                                            (
                                              prizePot *
                                              ((hislotteryPrizeAmount *
                                                hisrewardsBreakdown[2]) /
                                                10000)
                                            ).toFixed(2)
                                          )
                                            ? 0
                                            : (
                                                prizePot *
                                                ((hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[2]) /
                                                  10000)
                                              ).toFixed(2)}
                                        </span>
                                        {/* <span>85 BNJR each</span>
                                        <span> 48 Winning Tickets</span> */}
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="inbox">
                                        <p>Match first 4</p>
                                        <h5>
                                          {isNaN(
                                            (
                                              (hislotteryPrizeAmount *
                                                hisrewardsBreakdown[3]) /
                                              10000
                                            ).toFixed(2)
                                          )
                                            ? 0
                                            : (
                                                (hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[3]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                          BNJR
                                        </h5>
                                        <span>
                                          ~$
                                          {isNaN(
                                            (
                                              prizePot *
                                              ((hislotteryPrizeAmount *
                                                hisrewardsBreakdown[3]) /
                                                10000)
                                            ).toFixed(2)
                                          )
                                            ? 0
                                            : (
                                                prizePot *
                                                ((hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[3]) /
                                                  10000)
                                              ).toFixed(2)}
                                        </span>
                                        {/* <span> 0 Winning Tickets</span> */}
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="inbox">
                                        <p>Match first 5</p>
                                        <h5>
                                          {isNaN(
                                            (
                                              (hislotteryPrizeAmount *
                                                hisrewardsBreakdown[4]) /
                                              10000
                                            ).toFixed(2)
                                          )
                                            ? 0
                                            : (
                                                (hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[4]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                          BNJR
                                        </h5>
                                        <span>
                                          ~$
                                          {isNaN(
                                            (
                                              prizePot *
                                              ((hislotteryPrizeAmount *
                                                hisrewardsBreakdown[4]) /
                                                10000)
                                            ).toFixed(2)
                                          )
                                            ? 0
                                            : (
                                                prizePot *
                                                ((hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[4]) /
                                                  10000)
                                              ).toFixed(2)}
                                        </span>
                                        {/* <span> 0 Winning Tickets</span> */}
                                      </div>
                                    </div>
                                    <div className="col-md-3">
                                      <div className="inbox">
                                        <p>Match all 6</p>
                                        <h5>
                                          {isNaN(
                                            (
                                              (hislotteryPrizeAmount *
                                                hisrewardsBreakdown[5]) /
                                              10000
                                            ).toFixed(2)
                                          )
                                            ? 0
                                            : (
                                                (hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[5]) /
                                                10000
                                              ).toFixed(2)}{" "}
                                          BNJR
                                        </h5>
                                        <span>
                                          ~$
                                          {isNaN(
                                            (
                                              prizePot *
                                              ((hislotteryPrizeAmount *
                                                hisrewardsBreakdown[5]) /
                                                10000)
                                            ).toFixed(2)
                                          )
                                            ? 0
                                            : (
                                                prizePot *
                                                ((hislotteryPrizeAmount *
                                                  hisrewardsBreakdown[5]) /
                                                  10000)
                                              ).toFixed(2)}
                                        </span>
                                      </div>
                                    </div>
                                    {/* <div className="col-md-3">
                                      <div className="inbox">
                                        <p>Burn</p>
                                        <h5>4,548 BNJR</h5>
                                        <span>~$17,725</span>
                                        <span> 0 Winning Tickets</span>
                                      </div>
                                    </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <h2 class="accordion-header" id="flush-headingtwo">
                            <button
                              class="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#flush-collapsetwo"
                              aria-expanded="false"
                              aria-controls="flush-collapsetwo"
                            >
                              <span className="hide">Hide</span>
                              <span className="details">Details</span>
                            </button>
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="nav-two"
                    role="tabpanel"
                    aria-labelledby="nav-two-tab"
                  >
                    <div className="box m-0 yourhistory">
                      <div class="table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th scope="col">Round</th>
                              <th scope="col">Tickets</th>
                              <th scope="col">Date</th>
                              <th scope="col">view</th>
                            </tr>
                          </thead>
                          <tbody>
                            {console.log(allHist, "allHist")}
                            {allHist.map((data, key) => {
                              return (
                                <tr>
                                  <td>{data.ticketid}</td>
                                  <td>{data.count}</td>
                                  <td>
                                    {/* {data.enddate
                                      .toString()
                                      .split(" ")
                                      .splice(0, 5)
                                      .join(" ")} */}
                                    {moment(data.enddate).format("LLLL")}
                                  </td>
                                  <td>
                                    <button
                                      class="btn butn"
                                      data-bs-toggle="modal"
                                      data-bs-target="#viewtickets_modal1"
                                      onClick={() =>
                                        viewticketdetails(data.ticketid)
                                      }
                                    >
                                      view
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="howplay" id="howplay">
          <div className="howplaybg">
            <div className="container">
              <img
                src={require("../assets/images/howplay.png")}
                alt="Icon"
                className="img-fluid"
              />
              <div className="content">
                <div className="head">
                  <h2>How to Play</h2>
                  <p>
                    If the digits on your tickets match the winning numbers in
                    the correct order, you win a portion of the prize
                    pool.Simple!
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="box">
                      <h4>step1</h4>
                      <h5>Buy Tickets</h5>
                      <p>
                        Prices are set when the round starts,equal to 5 USD in
                        BNJR per ticket.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="box">
                      <h4>step2</h4>
                      <h5>Wait for the Draw</h5>
                      <p>
                        There is one draw every day alternating between 0 AM UTC
                        and 12 PM UTC.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="box">
                      <h4>step3</h4>
                      <h5>Check for Prizes</h5>
                      <p>
                        Once the round’s over, come back to the page and check
                        to see if you’ve won!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="curve"></div>
        </section>

        <section className="winning ">
          <div className="winningbg">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="head">
                    <h2 className="h2tags">Winning Criteria</h2>
                    <p>
                      The digits on your ticket must match in the correct order
                      to win.
                    </p>
                  </div>
                  <p>
                    Here’s an example lottery draw, with two tickets, A and B.
                  </p>
                  <ul>
                    <li>
                      <span>Ticket A:</span> The first 3 digits and the last 2
                      digits match, but the 4th digit is wrong, so this ticket
                      only wins a “Match first 3” prize.
                    </li>
                    <li>
                      <span>Ticket B:</span>Even though the last 5 digits match,
                      the first digit is wrong, so this ticket doesn’t win a
                      prize.
                    </li>
                  </ul>
                  <p>
                    Prize brackets don’t ‘stack’: if you match the first 3
                    digits in order, you’ll only win prizes from the ‘Match 3’
                    bracket, and not from ‘Match 1’ and ‘Match 2’.
                  </p>
                </div>
                <div className="col-lg-4 m-auto">
                  <div className="img_box">
                    <img
                      src={require("../assets/images/winning.png")}
                      alt="Icon"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* curve shape */}
        <div className="winning curve">
          <div className="container">
            <div className="row">
              <div className="col-lg-8"> </div>
              <div className="col-lg-4 m-auto">
                <div className="img_box"></div>
              </div>
            </div>
          </div>
        </div>

        <section className="winning prize">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 m-auto">
                <div className="img_box">
                  <div className="text-center mb-4">
                    <img
                      src={require("../assets/images/chart.png")}
                      alt="Icon"
                      className="img-fluid"
                    />
                  </div>
                  <ul>
                    <li>
                      <p>Digits Matched</p>
                      <p>Prize Pool Allocation</p>
                    </li>
                    <li>
                      <p className="dot">Matches first 1</p>
                      <p>
                        {rewardsBreakdown[0] ? rewardsBreakdown[0] / 100 : 0}%
                      </p>
                    </li>
                    <li>
                      <p className="dot">Matches first 2</p>
                      <p>
                        {rewardsBreakdown[1] ? rewardsBreakdown[1] / 100 : 0}%
                      </p>
                    </li>
                    <li>
                      <p className="dot">Matches first 3</p>
                      <p>
                        {rewardsBreakdown[2] ? rewardsBreakdown[2] / 100 : 0}%
                      </p>
                    </li>
                    <li>
                      <p className="dot">Matches first 4</p>
                      <p>
                        {rewardsBreakdown[3] ? rewardsBreakdown[3] / 100 : 0}%
                      </p>
                    </li>
                    <li>
                      <p className="dot">Matches first 5</p>
                      <p>
                        {rewardsBreakdown[4] ? rewardsBreakdown[4] / 100 : 0}%
                      </p>
                    </li>
                    <li>
                      <p className="dot">Matches first 6</p>
                      <p>
                        {rewardsBreakdown[5] ? rewardsBreakdown[5] / 100 : 0}%
                      </p>
                    </li>
                    {/* <li>
                      <p className="dot">Burn Pool </p>
                      <p>20%</p>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="head">
                  <h2 className="h2tags">Prize Funds</h2>
                  <p>
                    The prizes for each lottery round come from three sources:
                  </p>
                </div>
                <p>
                  <span>Ticket Purchases</span> 100% of the BNJR paid by people
                  buying tickets that round goes back into the prize pools.
                </p>
                <p>
                  <span>Rollover Prizes</span>After every round, if nobody wins
                  in one of the prize brackets, the unclaimed BNJR for that
                  bracket rolls over into the next round and are redistributed
                  among the prize pools.
                </p>
                <p>
                  <span>BNJR Injections</span>An average total of 35,000 BNJR
                  from the treasury is added to lottery rounds over the course
                  of a week. This BNJR is of course also included in rollovers!
                  Read more in our guide to <Link to="#"> BNJR Tokenomics</Link>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="question">
          <div className="container">
            <h2 className="h2tags">Still got questions?</h2>
            <p>Check our in-depth guide on</p>
            <p>
              {/* <Link to="#"> how to play the Bonjour lottery!</Link> */}
              <a href="#howplay">how to play the Bonjour lottery!</a>
            </p>
          </div>
        </section>
      </div>
      <Footer />
      <div
        class="modal fade buy_modal"
        id="viewtickets_modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="viewtickets_modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="viewtickets_modalLabel">
                Round {Ticid}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <h4>Winning Number</h4>
              <div className="finished_rounds mt-3">
                <div class="numbers">
                  <div class="round round1">{finalNumber[6]}</div>
                  <div class="round round2">{finalNumber[5]}</div>
                  <div class="round round3">{finalNumber[4]}</div>
                  <div class="round round4">{finalNumber[3]}</div>
                  <div class="round round5">{finalNumber[2]}</div>
                  <div class="round round6">{finalNumber[1]}</div>
                </div>
              </div>
              <h4 className="mt-5">Your Tickets</h4>
              <div className="d-flex align-items-center justify-content-between">
                <label>Total Tickets:</label>
                <label>{currentWinningNumber.length}</label>
              </div>
              <p className="mt-3">#{Ticid}</p>

              {currentWinningNumber.map((data) => {
                var digits = data.toString().split("");
                var realDigits = digits.map(Number);
                return (
                  <div className="view_tickets_number">
                    {console.log(TicfinalNumber, "TicfinalNumber")}
                    {TicfinalNumber[6] == realDigits[6] ? (
                      <p className="winning_round_selected">{realDigits[6]}</p>
                    ) : (
                      <span>{realDigits[6]}</span>
                    )}

                    {TicfinalNumber[6] == realDigits[6] &&
                    TicfinalNumber[5] == realDigits[5] ? (
                      <p className="winning_round_selected">{realDigits[5]}</p>
                    ) : (
                      <span>{realDigits[5]}</span>
                    )}

                    {TicfinalNumber[6] == realDigits[6] &&
                    TicfinalNumber[5] == realDigits[5] &&
                    TicfinalNumber[4] == realDigits[4] ? (
                      <p className="winning_round_selected">{realDigits[4]}</p>
                    ) : (
                      <span>{realDigits[4]}</span>
                    )}

                    {TicfinalNumber[6] == realDigits[6] &&
                    TicfinalNumber[5] == realDigits[5] &&
                    TicfinalNumber[4] == realDigits[4] &&
                    TicfinalNumber[3] == realDigits[3] ? (
                      <p className="winning_round_selected">{realDigits[3]}</p>
                    ) : (
                      <span>{realDigits[3]}</span>
                    )}

                    {TicfinalNumber[6] == realDigits[6] &&
                    TicfinalNumber[5] == realDigits[5] &&
                    TicfinalNumber[4] == realDigits[4] &&
                    TicfinalNumber[3] == realDigits[3] &&
                    TicfinalNumber[2] == realDigits[2] ? (
                      <p className="winning_round_selected">{realDigits[2]}</p>
                    ) : (
                      <span>{realDigits[2]}</span>
                    )}

                    {TicfinalNumber[6] == realDigits[6] &&
                    TicfinalNumber[5] == realDigits[5] &&
                    TicfinalNumber[4] == realDigits[4] &&
                    TicfinalNumber[3] == realDigits[3] &&
                    TicfinalNumber[2] == realDigits[2] &&
                    TicfinalNumber[1] == realDigits[1] ? (
                      <p className="winning_round_selected">{realDigits[1]}</p>
                    ) : (
                      <span>{realDigits[1]}</span>
                    )}
                  </div>
                );
              })}
              {new Date(Endlotterytime) >= new Date() ? (
                <button className="btn butn" disabled="true">
                  {" "}
                  Claim
                </button>
              ) : claim ? (
                <button className="btn butn" onClick={() => claimRewards()}>
                  {loader && <i class="fas fa-spinner fa-spin mr-2"></i>} Claim
                </button>
              ) : (
                <button className="btn butn" disabled="true">
                  {" "}
                  Rewards claimed
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div
        class="modal fade buy_modal"
        id="viewtickets_modal1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="viewtickets_modalLabel1"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="viewtickets_modalLabel1">
                View Ticket
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <h4>Winning Number</h4>
              <div className="finished_rounds mt-3">
                <div class="numbers">
                  <div class="round round1">{finalNumber[6]}</div>
                  <div class="round round2">{finalNumber[5]}</div>
                  <div class="round round3">{finalNumber[4]}</div>
                  <div class="round round4">{finalNumber[3]}</div>
                  <div class="round round5">{finalNumber[2]}</div>
                  <div class="round round6">{finalNumber[1]}</div>
                </div>
              </div>
              <h4 className="mt-5">Your Tickets</h4>
              <div className="d-flex align-items-center justify-content-between">
                <label>Total Tickets:</label>
                <label>{currentWinningNumber.length}</label>
              </div>
              <p className="mt-3">#{Ticid}</p>

              {currentWinningNumber.map((data) => {
                var digits = data.toString().split("");
                var realDigits = digits.map(Number);
                return (
                  <div className="view_tickets_number">
                    {console.log(TicfinalNumber, "TicfinalNumber")}
                    {TicfinalNumber[6] == realDigits[6] ? (
                      <p className="winning_round_selected">{realDigits[6]}</p>
                    ) : (
                      <span>{realDigits[6]}</span>
                    )}

                    {TicfinalNumber[6] == realDigits[6] &&
                    TicfinalNumber[5] == realDigits[5] ? (
                      <p className="winning_round_selected">{realDigits[5]}</p>
                    ) : (
                      <span>{realDigits[5]}</span>
                    )}

                    {TicfinalNumber[6] == realDigits[6] &&
                    TicfinalNumber[5] == realDigits[5] &&
                    TicfinalNumber[4] == realDigits[4] ? (
                      <p className="winning_round_selected">{realDigits[4]}</p>
                    ) : (
                      <span>{realDigits[4]}</span>
                    )}

                    {TicfinalNumber[6] == realDigits[6] &&
                    TicfinalNumber[5] == realDigits[5] &&
                    TicfinalNumber[4] == realDigits[4] &&
                    TicfinalNumber[3] == realDigits[3] ? (
                      <p className="winning_round_selected">{realDigits[3]}</p>
                    ) : (
                      <span>{realDigits[3]}</span>
                    )}

                    {TicfinalNumber[6] == realDigits[6] &&
                    TicfinalNumber[5] == realDigits[5] &&
                    TicfinalNumber[4] == realDigits[4] &&
                    TicfinalNumber[3] == realDigits[3] &&
                    TicfinalNumber[2] == realDigits[2] ? (
                      <p className="winning_round_selected">{realDigits[2]}</p>
                    ) : (
                      <span>{realDigits[2]}</span>
                    )}

                    {TicfinalNumber[6] == realDigits[6] &&
                    TicfinalNumber[5] == realDigits[5] &&
                    TicfinalNumber[4] == realDigits[4] &&
                    TicfinalNumber[3] == realDigits[3] &&
                    TicfinalNumber[2] == realDigits[2] &&
                    TicfinalNumber[1] == realDigits[1] ? (
                      <p className="winning_round_selected">{realDigits[1]}</p>
                    ) : (
                      <span>{realDigits[1]}</span>
                    )}
                  </div>
                );
              })}
              {new Date(Endlotterytime) >= new Date() ? (
                <button className="btn butn" disabled="true">
                  {" "}
                  Claim
                </button>
              ) : claim ? (
                <button className="btn butn" onClick={() => claimRewards()}>
                  {" "}
                  Claim
                </button>
              ) : (
                <button className="btn butn" disabled="true">
                  {" "}
                  Rewards claimed
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
