import React, { useEffect } from "react";
import { useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { Link } from "react-router-dom";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { getprovider } from "../pages/seperate/provider";
import Web3 from "web3";
import LotterytokenABI from "../ABI/Lotterytoken.json";
import config from "../lib/config/config";
import LotteryABI from "../ABI/Lottery.json";
import ConnectWallet from "../pages/seperate/Connect-Wallet";
import { getCurAddr } from "../actions/User";
import moment from "moment";
import "../App.css";
import isEmpty from "../lib/isEmpty";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import isValidation from "./validation";
import { addLottery, addLotterySettings, getAddress } from "../actions/admin";

import lotteryABI from "../ABI/Lottery.json";

// toast.configure();

let toasterOption = config.toasterOption;

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

const initialFormValue = {
  operator: "",
  treasury: "",
  injector: "",
};

const initialFormValue1 = {
  lotteryId: "",
  endTime: "",
  ticketPice: "",
  discount: 2000,
  rew1: "",
  rew2: "",
  rew3: "",
  rew4: "",
  rew5: "",
  rew6: "",
  fees: 3000,
};

export default function Settings(props) {
  const [open, setOpen] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [provider, setprovider] = React.useState();
  const [address, setaddress] = React.useState();

  const [UserAccountAddr, Set_UserAccountAddr] = React.useState("");
  const [UserAccountBal, Set_UserAccountBal] = React.useState(0);
  const [WalletConnected, Set_WalletConnected] = React.useState("false");
  const [AddressUserDetails, Set_AddressUserDetails] = useState({});
  const [Accounts, Set_Accounts] = React.useState("");
  ////

  const [progress, setprogress] = useState("false");
  const [withdrawprogress, setwithdrawprogress] = useState("false");
  const [earnprogress, setearnprogress] = useState("false");
  const [minprogress, setminprogress] = useState("false");
  const [records, setrecords] = useState([]);
  const [withamt, setwithamt] = useState(0);
  const [withaddress, setwithaddress] = useState("");
  const [earnlimit, setearnlimit] = useState(0);
  const [minval, setminval] = useState(0);
  const [globalpoolamount, setglobalpoolamount] = useState(0);
  const [globalshare, setglobalshare] = useState(0);
  const [usertableheader, setusertableheader] = useState([
    "SNO",
    "USER ID",
    "ADDRESS",
    "Invest_Amount",
    "Re-Invest_Amount",
    "Claimed_Amount",
    "Global_Claimed_Amount",
  ]);
  const [tableheader, settableheader] = useState([
    "SNO",
    "USER ID",
    "ADDRESS",
    "Activity",
    "Amount",
    "DATE",
  ]);
  const [tabledata, settabledata] = useState([]);
  const [usertabledata, setusertabledata] = useState([]);
  const [claimprogress, setclaimprogress] = useState("false");
  const [removeprogress, setremoveprogress] = useState("false");
  const [claimaddress, setclaimaddress] = useState("");
  const [claimlist, setclaimlist] = useState([]);
  var today = new Date();
  var dd = today.getDate();
  var mm = today.getMonth() + 1; //January is 0!
  var yyyy = today.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }

  today = yyyy + "-" + mm + "-" + dd;
  let dateafter = parseInt(dd) + 1;
  let nextday = yyyy + "-" + mm + "-" + dateafter;
  const [currentdate, setcurrentdate] = useState(today);
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [currentevent, setcurrentevent] = useState("all");
  const [filterrec, setfilterrec] = useState([]);
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const [submitButton, setsubmitButton] = useState(false);
  const { operator, injector, treasury } = formValue;
  const [formValue1, setFormValue1] = useState(initialFormValue1);
  const {
    lotteryId,
    endTime,
    ticketPice,
    discount,
    rew1,
    rew2,
    rew3,
    rew4,
    rew5,
    rew6,
    fees,
  } = formValue1;
  const history = useNavigate();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  const AfterWalletConnected = async () => {
    var currAddr = await getCurAddr();
    if (currAddr) {
      //getTokenbal();
    }
  };
  // useEffect(() => {
  //   getmyprovider();
  // }, [provider, address]);

  useEffect(() => {
    getmyprovider();
  }, []);

  // function
  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
    console.log(formValue);
  };

  async function getmyprovider() {
    var { provider, address } = await getprovider();
    setprovider(provider);
    setaddress(address);
  }

  useEffect(() => {
    getaddress();
  }, []);

  const handleFormSubmit = async (e) => {
    console.log("saran");
    e.preventDefault();
    setsubmitButton(true);
    if (!window.ethereum) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      setsubmitButton(false);
      return false;
    }
    var web3 = new Web3(window.ethereum);
    var currAddr = window.web3.eth.defaultAccount;
    if (!currAddr) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      setsubmitButton(false);
      return false;
    }
    var currAddress = await web3.eth.getAccounts();

    console.log(
      config.ContractAddress,
      config.lotterAdmin,
      currAddress[0],
      "pankuku"
    );

    if (currAddress[0] != config.lotterAdmin) {
      toast.error("Please  Connect with Owner Wallet", toasterOption);
      setsubmitButton(false);
      return false;
    }

    const Contract = new web3.eth.Contract(lotteryABI, config.ContractAddress);
    if (
      !web3.utils.isAddress(operator) ||
      !web3.utils.isAddress(treasury) ||
      !web3.utils.isAddress(injector)
    ) {
      toast.warning("Check Your address Format", toasterOption);
      setsubmitButton(false);
      return false;
    }
    console.log(formValue, "formValue");
    console.log(currAddr, "currAddr");
    var transactionHash = "";
    var result = await Contract.methods
      .setOperatorAndTreasuryAndInjectorAddresses(operator, treasury, injector)
      .send({ from: currAddr })
      .then((output) => {
        console.log(output, "output");
        if (output.transactionHash) {
          transactionHash = output.transactionHash;
        } else {
          transactionHash = output;
        }
        console.log(output);
      })
      .catch((error) => {
        console.log(error);
        setsubmitButton(false);
        return false;
      });
    // console.log(result.transactionHash,'transactionhash')

    // if (result.transactionHash) {
    //   transactionHash = result.transactionHash;
    // } else {
    //   transactionHash = result;
    // }

    let reqData = {
      operator,
      treasury,
      injector,
      transactionHash,
    };

    let { error } = await addLotterySettings(reqData);

    if (isEmpty(error)) {
      setsubmitButton(false);
      toast.success("Settings updated successfully", toasterOption);
    } else {
      setsubmitButton(false);
      setValidateError(error);
    }
    console.log("error");
  };

  async function getaddress() {
    var data = await getAddress();
    console.log(data, "datadatadatadata");
    if (data && data.userValue != undefined) {
      let formdata = {};
      formdata["operator"] = data.userValue.operator;
      formdata["treasury"] = data.userValue.treasury;
      formdata["injector"] = data.userValue.injector;
      setFormValue(formdata);
    }
  }

  async function convert(n) {
    try {
      var sign = +n < 0 ? "-" : "",
        toStr = n.toString();
      if (!/e/i.test(toStr)) {
        return n;
      }
      var [lead, decimal, pow] = n
        .toString()
        .replace(/^-/, "")
        .replace(/^([0-9]+)(e.*)/, "$1.$2")
        .split(/e|\./);
      return +pow < 0
        ? sign +
            "0." +
            "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
            lead +
            decimal
        : sign +
            lead +
            (+pow >= decimal.length
              ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
              : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
    } catch (err) {
      return 0;
    }
  }
  const onChange1 = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue1, ...{ [id]: value } };
    setFormValue1(formData);
    console.log(formValue1);
  };

  const handleFormSubmit1 = async (e) => {
    e.preventDefault();
    let reqData = {
      endTime,
      ticketPice,
      rew1,
      rew2,
      rew3,
      rew4,
      rew5,
      rew6,
    };
    console.log(reqData, "formdata");
    let error = isValidation(reqData);
    console.log(error, "errors");
    if (!isEmpty(error)) {
      setValidateError(error);
      toast.warning("Please Enter Required Fields", toasterOption);
      return false;
    }
    setsubmitButton(true);
    var arra = [];
    arra.push(parseInt(rew1));
    arra.push(parseInt(rew2));
    arra.push(parseInt(rew3));
    arra.push(parseInt(rew4));
    arra.push(parseInt(rew5));
    arra.push(parseInt(rew6));

    if (!window.ethereum) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      setsubmitButton(false);
      return false;
    }
    var web3 = new Web3(window.ethereum);
    var currAddr = window.web3.eth.defaultAccount;
    if (!currAddr) {
      toast.warning("OOPS!..connect Your Wallet", toasterOption);
      setsubmitButton(false);
      return false;
    }
    if (
      parseInt(rew1) +
        parseInt(rew2) +
        parseInt(rew3) +
        parseInt(rew4) +
        parseInt(rew5) +
        parseInt(rew6) >
        10000 ||
      parseInt(rew1) +
        parseInt(rew2) +
        parseInt(rew3) +
        parseInt(rew4) +
        parseInt(rew5) +
        parseInt(rew6) <
        10000
    ) {
      toast.warning("Total spilt up must be 10000", toasterOption);
      setsubmitButton(false);
      return false;
    }

    var currentDate = new Date();

    // var timeMIn = new Date(currentDate.setMinutes(currentDate.getHours() + 4));
    var timeMIn = new Date();

    // var timeMIn = new Date(
    //   currentDate.setHours((currentDate.getHours()) + 4)
    // );

    var timeMax = new Date(currentDate.setDate(currentDate.getDate() + 4));

    var endDate = new Date(endTime * 1000);

    console.log(endDate, timeMIn, timeMax, "saranTestingLOggggk");

    // if (endDate < timeMIn) {
    //   toast.warning("It must be greater than 4 hours", toasterOption);
    //   setsubmitButton(false);
    //   return false;
    // }

    if (endDate > timeMax) {
      toast.warning("It must be lesser than 4 days", toasterOption);
      setsubmitButton(false);
      return false;
    }

    const Contract = new web3.eth.Contract(lotteryABI, config.ContractAddress);
    let convertamout = ticketPice * 1000000000000000000;
    var amountFIn = await convert(convertamout);
    var result = await Contract.methods
      .startLottery(endTime, amountFIn.toString(), 2000, arra, 3000)
      .send({ from: currAddr })
      .then(async (result) => {
        var ticketId = result.events.LotteryOpen.returnValues.lotteryId;

        var transactionHash = "";

        if (result.transactionHash) {
          transactionHash = result.transactionHash;
        } else {
          transactionHash = result;
        }

        let reqData = {
          ticketId,
          endTime,
          ticketPice,
          discount,
          rew1,
          rew2,
          rew3,
          rew4,
          rew5,
          rew6,
          fees,
          transactionHash,
          endDate,
        };

        let { error } = await addLottery(reqData);

        setsubmitButton(false);
        toast.success("Lottery updated successfully", toasterOption);
        history.push("/LotteryList");
      })
      .catch((error) => {
        setsubmitButton(false);
        setValidateError(error);
        toast.warn("Ooops! Rejected", toasterOption);
      });

    // if (isEmpty(error)) {
    //   console.log("saran-------------");

    // } else {
    //   setsubmitButton(false);
    //   setValidateError(error);
    // }
    // console.log("error");
  };

  return (
    <div>
      <ConnectWallet
        Set_UserAccountAddr={Set_UserAccountAddr}
        Set_UserAccountBal={Set_UserAccountBal}
        Set_WalletConnected={Set_WalletConnected}
        Set_AddressUserDetails={Set_AddressUserDetails}
        Set_Accounts={Set_Accounts}
        WalletConnected={WalletConnected}
        AfterWalletConnected={AfterWalletConnected}
      />
      <Navbar
        handleDrawerOpen={handleDrawerOpen}
        open={open}
        handleMenu={handleMenu}
        handleClose={handleClose}
        anchorEl={anchorEl}
      />
      {/* <ScrollToTopOnMount /> */}

        <section className="setting_page">
            <div className="container">
     
              <div className="box mb-5">
                <h2 className="inner_title">Update Lottery Settings</h2>
                <div className="row">
                <div className="col-lg-6">
                  <label for="exampleFormControlInput1" class="form-label">
                    OPERATOR
                  </label>
                  <input
                    type="text"
                    class="form-control primary_inp"
                    id="operator"
                    value={operator}
                    placeholder="Enter Address"
                    onChange={onChange}
                  />
                  {validateError.operator && (
                    <span className="text-danger">
                      {validateError.operator}
                    </span>
                  )}
                  </div>
         
                <div className="col-lg-6">
                  <label for="exampleFormControlInput1" class="form-label">
                    TREASURY
                  </label>
                  <input
                    type="text"
                    class="form-control primary_inp"
                    id="treasury"
                    value={treasury}
                    placeholder="Enter Address"
                    onChange={onChange}
                  />
                  {validateError.treasury && (
                    <span className="text-danger">
                      {validateError.treasury}
                    </span>
                  )}
                  </div>
         
                <div className="col-lg-6">
                  <label for="exampleFormControlInput1" class="form-label">
                    INJECTOR
                  </label>
                  <input
                    type="text"
                    class="form-control primary_inp"
                    id="injector"
                    value={injector}
                    placeholder="Enter Address"
                    onChange={onChange}
                  />
                  {validateError.injector && (
                    <span className="text-danger">
                      {validateError.injector}
                    </span>
                  )}
                 </div>
        
                <div className="col-lg-12">
                  <button
                    className="primary_btn butn butn"
                    type="button"
                    onClick={handleFormSubmit}
                  >
                    {submitButton && (
                      <i class="fas fa-spinner fa-spin mr-2"></i>
                    )}
                    Add
                  </button>
                 
                </div>
                </div>
                {submitButton && (
                    <lable>Don't refersh when page loading...</lable>
                  )}
                </div>
                 
                <div className="box mb-5">
                <div className="row">
                <div className="col-lg-12">
                <h2 className="inner_title">Start Lottery</h2>
                <div className="row">
                <div className="col-lg-6">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            End time in timestamp
                          </label>
                          <input
                            type="text"
                            class="form-control primary_inp"
                            onChange={onChange1}
                            id="endTime"
                            value={endTime}
                          />
                          {validateError.endTime && (
                            <span className="text-danger">
                              {validateError.endTime}
                            </span>
                          )}
                          </div>
                          <div className="col-lg-6">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Ticket Prize
                          </label>
                          <input
                            type="text"
                            class="form-control primary_inp"
                            id="ticketPice"
                            value={ticketPice}
                            onChange={onChange1}
                          />
                          {validateError.ticketPice && (
                            <span className="text-danger">
                              {validateError.ticketPice}
                            </span>
                          )}
                          </div>
                              <div className="col-lg-6">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            First Prize %
                          </label>
                          <input
                            type="text"
                            class="form-control primary_inp"
                            id="rew1"
                            value={rew1}
                            onChange={onChange1}
                          />
                          {validateError.rew1 && (
                            <span className="text-danger">
                              {validateError.rew1}
                            </span>
                          )}
                          </div>
                          <div className="col-lg-6">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Second Prize %
                          </label>
                          <input
                            type="text"
                            class="form-control primary_inp"
                            id="rew2"
                            value={rew2}
                            onChange={onChange1}
                          />
                          {validateError.rew2 && (
                            <span className="text-danger">
                              {validateError.rew2}
                            </span>
                          )}
                          </div>
                          <div className="col-lg-6">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Third Prize %
                          </label>
                          <input
                            type="text"
                            class="form-control primary_inp"
                            id="rew3"
                            value={rew3}
                            onChange={onChange1}
                          />
                          {validateError.rew3 && (
                            <span className="text-danger">
                              {validateError.rew3}
                            </span>
                          )}
                          </div>
                          <div className="col-lg-6">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Fourth Prize %
                          </label>
                          <input
                            type="text"
                            class="form-control primary_inp"
                            id="rew4"
                            value={rew4}
                            onChange={onChange1}
                          />
                          {validateError.rew5 && (
                            <span className="text-danger">
                              {validateError.rew5}
                            </span>
                          )}
                          </div>
                          <div className="col-lg-6">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Fifth Prize %
                          </label>
                          <input
                            type="text"
                            class="form-control primary_inp"
                            id="rew5"
                            value={rew5}
                            onChange={onChange1}
                          />
                          {validateError.rew5 && (
                            <span className="text-danger">
                              {validateError.rew5}
                            </span>
                          )}
                          </div>
                          <div className="col-lg-6">
                          <label
                            for="exampleFormControlInput1"
                            class="form-label"
                          >
                            Sixth Prize %
                          </label>
                          <input
                            type="text"
                            class="form-control primary_inp"
                            id="rew6"
                            value={rew6}
                            onChange={onChange1}
                          />
                          {validateError.rew6 && (
                            <span className="text-danger">
                              {validateError.rew6}
                            </span>
                          )}
                          </div> 
                          <div className="col-lg-6">
                          <button
                            className="primary_btn butn butn"
                            type="button"
                            onClick={handleFormSubmit1}
                          >
                            {submitButton && (
                              <i class="fas fa-spinner fa-spin mr-2"></i>
                            )}
                            Add
                          </button>
                          </div>
                 </div>
                          {submitButton && (
                            <lable>Don't refersh when page loading...</lable>
                          )}
                 </div>
                </div>
                </div>


                <div className="box mb-5">
                <div className="row settings_row">
                            <div className="col-md-6">
                              <div>
                                <h2>Safe Withdraw</h2>
                                <div className="form-group row">
                                  <div className="col-lg-12 m-auto text-start">
                                    <label
                                      for="exampleFormControlInput1"
                                      class="form-label"
                                    >
                                      Withdraw Amount
                                    </label>
                                    <input
                                      type="Number"
                                      class="form-control primary_inp"
                                      id="exampleFormControlInput1"
                                      placeholder="Enter Amount"
                                      min="0"
                                    />
                                    <label
                                      for="exampleFormControlInput1"
                                      class="form-label"
                                    >
                                      Withdraw Address
                                    </label>
                                    <input
                                      type="text"
                                      class="form-control primary_inp"
                                      id="exampleFormControlInput1"
                                      placeholder="Enter Address"
                                    />
                                    <button
                                      className="primary_btn butn"
                                      type="button"
                                    >
                                      1111
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div>
                                <h2>Update Minimum Withdraw</h2>
                                <div className="form-group row">
                                  <div className="col-lg-12 m-auto text-start">
                                    <label
                                      for="exampleFormControlInput1"
                                      class="form-label"
                                    >
                                      Minimum Withdraw
                                    </label>
                                    <input
                                      type="Number"
                                      class="form-control primary_inp"
                                      id="exampleFormControlInput1"
                                      placeholder="Enter Minimum Value"
                                    />
                                    <button
                                      className="primary_btn butn"
                                      type="button"
                                    >
                                      2222
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {/* <div className="col-md-6">
                      <div className="primary_box dash_box mt-4">
                       <h2>Maximum Withdraw</h2>
                        <div className="form-group row">
                          <div className="col-lg-12 m-auto text-start">
                            <label for="exampleFormControlInput1" class="form-label">Maximum Withdraw</label>
                            <input type="Number" class="form-control primary_inp" id="exampleFormControlInput1" placeholder="Enter Maximum Value" />
                           <button className="primary_btn butn" type="button" onClick={progress=="false"?()=>distributeglobal():()=>empty()}>{progress=="false"?"Update":"Processing..Please Wait.."}</button>
                          </div>
                        </div>                    
                      </div> 
                    </div>*/}
                 </div>
                 </div>

                 <div className="box mb-5">
                 <div className="row">
                          <div class="col-lg-12">
                            <div className="primary_box dash_box">
                              <div className="">
                                <h2>
                                  {"Global Pool Distribution ( Global Volume : " +
                                    (
                                      parseFloat(globalpoolamount) / 1e18
                                    ).toFixed(2) +
                                    " )"}
                                </h2>
                                <div className="row">
                          <div class="col-lg-6">
                                <div>
                                  <label
                                    for="exampleFormControlInput1"
                                    class="form-label"
                                  >
                                    {"Global Pool Amount"}
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control primary_inp"
                                    id="exampleFormControlInput1"
                                    placeholder="Enter Amount"
                                  />
                                  <button className="primary_btn butn" type="button">
                                    33333
                                  </button>
                                </div>
                                <div className="plan_income_table_div">
                                  <div className="table-responsive">
                                    <table className="table plan_income_table mt-4 w-100">
                                      <thead>
                                        <tr>
                                          <th>S.No</th>
                                          <th>Address</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {records &&
                                          records.length > 0 &&
                                          records.map((item, index) => {
                                            return (
                                              <tr>
                                                <td>{parseInt(index) + 1}</td>
                                                <td>{item}</td>
                                              </tr>
                                            );
                                          })}
                                        {records && records.length <= 0 && (
                                             <tr>
                                             <td colSpan={2}>
                                          <p className="text-center mt-2">
                                            No Records Found
                                          </p>
                                          </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div class="col-lg-6">
                            <div className="primary_box dash_box">
                              <div className="">
                                <h2>Claim block address</h2>
                                <div>
                                  <label
                                    for="exampleFormControlInput1"
                                    class="form-label"
                                  >
                                    Wallet Address
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control primary_inp"
                                    id="exampleFormControlInput1"
                                    placeholder="Enter Address"
                                  />
                                  <button className="primary_btn butn" type="button">
                                    44444
                                  </button>
                                </div>
                                <div className="plan_income_table_div">
                                  <div className="table-responsive">
                                    {removeprogress == "true" && (
                                      <p className="text-center mt-2">
                                        Processing ... Please Wait....
                                      </p>
                                    )}
                                    <table className="table plan_income_table mt-4 w-100">
                                      <thead>
                                        <tr>
                                          <th>S.No</th>
                                          <th>Address</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {claimlist &&
                                          claimlist.length > 0 &&
                                          claimlist.map((item, index) => {
                                            return (
                                              <tr>
                                                <td>{parseInt(index) + 1}</td>
                                                <td>{item.address}</td>
                                                <td>
                                                  <button
                                                    className="primary_btn butn"
                                                    type="button"
                                                  >
                                                    Remove
                                                  </button>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        {claimlist && claimlist.length <= 0 && (
                                          <p className="text-center mt-2">
                                            No Records Found
                                          </p>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                 </div>

                 <div className="box mb-5">
                 <div className="row">
                 <div class="col-lg-6">
                            <div className="primary_box dash_box">
                              <div className="">
                                <h2>Claim block address</h2>
                                <div>
                                  <label
                                    for="exampleFormControlInput1"
                                    class="form-label"
                                  >
                                    Wallet Address
                                  </label>
                                  <input
                                    type="text"
                                    class="form-control primary_inp"
                                    id="exampleFormControlInput1"
                                    placeholder="Enter Address"
                                  />
                                  <button className="primary_btn butn" type="button">
                                    44444
                                  </button>
                                </div>
                                <div className="plan_income_table_div">
                                  <div className="table-responsive">
                                    {removeprogress == "true" && (
                                      <p className="text-center mt-2">
                                        Processing ... Please Wait....
                                      </p>
                                    )}
                                    <table className="table plan_income_table mt-4 w-100">
                                      <thead>
                                        <tr>
                                          <th>S.No</th>
                                          <th>Address</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {claimlist &&
                                          claimlist.length > 0 &&
                                          claimlist.map((item, index) => {
                                            return (
                                              <tr>
                                                <td>{parseInt(index) + 1}</td>
                                                <td>{item.address}</td>
                                                <td>
                                                  <button
                                                    className="primary_btn butn"
                                                    type="button"
                                                  >
                                                    Remove
                                                  </button>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        {claimlist && claimlist.length <= 0 && (
                                            <tr>
                                            <td colSpan={3}>
                                          <p className="text-center mt-2">
                                            No Records Found
                                          </p>
                                          </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                  </div>
                  </div>


                 <div className="box">
                 <div className="row settings_row">
                          <div className="col-lg-12">
                            <div className="primary_box dash_box">
                              <div className="">
                                <h2>Fetch Records</h2>
                                <div className="row">
                                  <div className="col-lg-6 text-start">
                                    <label
                                      for="exampleFormControlInput1"
                                      class="form-label"
                                    >
                                      Start Date
                                    </label>
                                    <input
                                      type="datetime-local"
                                      class="form-control primary_inp"
                                      id="startdate"
                                    />
                                  </div>
                                  <div className="col-lg-6 text-start">
                                    <label
                                      for="exampleFormControlInput1"
                                      class="form-label"
                                    >
                                      End Date
                                    </label>
                                    <input
                                      type="datetime-local"
                                      class="form-control primary_inp"
                                      id="enddate"
                                    />
                                  </div>

                                  <div className="col-lg-6 text-start">
                                    <label
                                      for="exampleFormControlInput1"
                                      class="form-label"
                                    >
                                      Events
                                    </label>
                                    <select class="form-control primary_inp">
                                      <option value="all" selected>
                                        All
                                      </option>
                                      <option value="joined">Joined</option>
                                      <option value="reinvest">
                                        Re-Invest
                                      </option>
                                      {/*<option value="retopup" >Re-Topup</option>*/}
                                      <option value="claim">Claim</option>
                                      <option value="globalclaim">
                                        Global Claim
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="text-end">
                                  {/*<button className="primary_btn butn" type="button" onClick={()=>saveuserAsExcel()}>Download User Details</button>*/}
                                  <button className="primary_btn butn" type="button">
                                    Download History
                                  </button>
                                </div>
                                <div className="plan_income_table_div">
                                  <div className="table-responsive">
                                    <table
                                      className="table plan_income_table mt-4 w-100"
                                      id="filtertable"
                                    >
                                      <thead>
                                        <tr>
                                          <th>S.No</th>
                                          <th>User Id</th>
                                          <th>Address</th>
                                          <th>Activity</th>
                                          <th>Amount</th>
                                          <th>Date</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {filterrec &&
                                          filterrec.length > 0 &&
                                          filterrec.map((item, index) => {
                                            return (
                                              <tr>
                                                <td>{parseInt(index) + 1}</td>
                                                <td>{item.userid}</td>
                                                <td>{item.address}</td>
                                                <td>
                                                  {item.event == "joined"
                                                    ? "Join"
                                                    : item.event == "reinvest"
                                                    ? "Re-Invest"
                                                    : item.event == "retopup"
                                                    ? "Re-Topup"
                                                    : item.event ==
                                                      "globalclaim"
                                                    ? "Claim"
                                                    : "Claim"}
                                                </td>
                                                <td>
                                                  {parseInt(
                                                    item.amount
                                                  ).toString().length > 8
                                                    ? (
                                                        parseFloat(
                                                          item.amount
                                                        ) / 1e18
                                                      ).toFixed(2)
                                                    : parseFloat(
                                                        item.amount
                                                      ).toFixed(2)}
                                                </td>
                                                <td>
                                                  {moment(
                                                    item.createdAt
                                                  ).format(
                                                    "MMM Do YYYY, h:mm:ss A"
                                                  )}
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        {filterrec && filterrec.length <= 0 && (
                                           <tr>
                                           <td colSpan={6}>
                                          <p className="text-center mt-2">
                                            No Records Found
                                          </p>
                                          </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                 </div>


            </div>
        </section>

      
    </div>
  );
}
