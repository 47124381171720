import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import kukuapi from "../ABI/KUKU.json";
import wethabi from "../ABI/BEP721.json";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import "@metamask/legacy-web3";
import config from "../lib/config/config";
import { toast } from "react-toastify";
import { toastAlert } from "../helper/toastAlert";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import {
  MenuOpen,
  Notifications,
  AccountBalanceWallet,
  MeetingRoom,
} from "@material-ui/icons";
import { getprovider } from "../pages/seperate/provider";
import LotterytokenABI from "../ABI/Lotterytoken.json";

let toasterOption = config.toasterOption;

export default function Navbar(props) {
  const [selected, setSelected] = useState("GB");

  const [account, setaccount] = useState();

  const { classes, handleDrawerOpen, open, handleMenu, handleClose, anchorEl } =
    props;

  const [currAddr, setcurrAddr] = React.useState("");
  const [blns, setBln] = React.useState(0);
  const [tokenbalance, settokenbalance] = React.useState(0);
  const [wethbalance, setwethbalance] = React.useState(0);

  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 100) {
        $(".main_navbar").addClass("fixed_navbar");
      } else {
        $(".main_navbar").removeClass("fixed_navbar");
      }
    });

    // $(document).ready(function () {
    //   $(".main_navbar ul.navbar-nav li a").click(function () {
    //     $(".navbar-toggler").trigger("click");
    //   });
    // });
  }

  useEffect(() => {
    getconnect();
  }, []);

  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 10) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  async function getconnect() {
    var { provider, address } = await getprovider();
    console.log(provider, "provider");
    if (provider) {
      var web3 = new Web3(provider);
      try {
        if (typeof provider !== "undefined") {
          setcurrAddr(address);
          var balance = await web3.eth.getBalance(address);
          var currbal = balance / 1000000000000000000;
          setBln(currbal.toFixed(5));
          var CoursetroContract = new web3.eth.Contract(
            LotterytokenABI,
            config.lotteryToken
          );
          var tokenDethBln = await CoursetroContract.methods
            .balanceOf(address)
            .call();
          var value = tokenDethBln / 1000000000000000000;
          settokenbalance(value.toFixed(2));
          var CoursetroContract1 =new web3.eth.Contract(
            LotterytokenABI,
            config.lotteryToken
          );
          var tokenDethBln1 = await CoursetroContract1.methods
            .balanceOf(address)
            .call();
          var value1 = tokenDethBln1 / 1000000000000000000;
          setwethbalance(value1.toFixed(2));
          console.log(currbal,value1,"valuefgyfg")

        } else {
          //  toast.success("Please Add Metamask External", toasterOption);
        }
      } catch (err) {}
    } else {
      // toast.success("Please Add Metamask External", toasterOption);
    }
  }

  

  async function handleWallet() {
    if (window.ethereum) {
      var web3 = new Web3(window.ethereum);
      try {
        if (typeof web3 !== "undefined") {
          window.ethereum
            .enable()
            .then(async function () {
              const web3 = new Web3(window.web3.currentProvider);
              await web3.eth.getAccounts(async function (error, result) {
                localStorage.setItem("tfnukukpannft", "yes");
                window.location.reload(false);
              });
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
        }
      } catch (err) {
        console.log(err, "wallet catch err");
      }
    } else {
      console.log("Please add metamask");
    }
  }



  async function disconnectMetamask() {
    if (localStorage.getItem("tfnukukpannft")) {
      localStorage.removeItem("tfnukukpannft");
      localStorage.removeItem("walltype");
      localStorage.removeItem("account");
      localStorage.removeItem("walletconnect");
      window.location.reload(false);
    }
  }



  async function connectTrustwallet() {
    var web3 = new Web3(window.ethereum);
      const provider1 = new WalletConnectProvider({
        rpc: {
          56: "https://bsc-dataseed.binance.org/",
        },
        chainId: 56,
      });
    provider1.on("connect", () => {
      setTimeout(() => window.$("#connect_wallet_modal").modal("hide"), 600);
      setTimeout(() => window.location.reload(false), 1200);
    });
    await provider1.enable();
    web3 = new Web3(provider1);
    let cid = await web3.eth.getAccounts();
    web3.eth.net.getId().then(async (res) => {
      console.log(res, "res");
      if (res == 56) {
        var getaccounts = await web3.eth.getAccounts();
        console.log(getaccounts, "accounts");
        localStorage.setItem("tfnukukpannft", "yes");
        localStorage.setItem("walltype", "trust");
        localStorage.setItem("account", getaccounts[0]);
        toast.success("wallet connected", toasterOption);
      } else {
        toast.warning("Connect to Binance Mainnet", toasterOption);
        setaccount("");
        return false;
      }
    });
  }

  return (
    <>
      <nav className="navbar navbar-light navbar-expand-sm bg-light main_navbar">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            <img
              src={require("../assets/images/logo.png")}
              className="img-fluid"
              alt="Icon"
            />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i class="fas fa-bars"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarScroll">
          {!localStorage.getItem("tfnukukpannft") ? (
              <div className="collapse navbar-collapse" id="navbarScroll">
              <ul class="navbar-nav ms-auto mb-2 mb-lg-0 navbar_right">
                <li class="nav-item">
                  <button
                    className="btn butn my-0"
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet_modal"
                  >
                    <img
                      src={require("../assets/images/wallet.png")}
                      alt="Icon"
                      className="img-fluid"
                    />
                    CONNECT WALLET
                  </button>
                </li>
              </ul>
              </div>
            ) : (
            <div className="ms-auto" >
              <IconButton
                aria-owns={open ? "menu-appbar" : undefined}
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                className="profileButton butn"
              >
                <AccountBalanceWallet />
                <Typography className="wallet_address">{halfAddrShow(currAddr)}</Typography>
              </IconButton>
              <Menu 
                style={{marginTop: "50px", marginLeft: "50px"}}
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className="menudrop"
              >
                <MenuItem>
                  <div className="dropProfilePic">
                    <Typography className="dropProfileName mb-3">
                      <span>Balance in BNB</span>: {blns}
                    </Typography>
                    <Typography className="dropProfileName">
                      <span>Balance in {config.tokenSymbol}</span>:{" "}
                      {tokenbalance}
                    </Typography>
                  </div>
                </MenuItem>

                <MenuItem onClick={() => disconnectMetamask()}>
                  <MeetingRoom /> Logout
                </MenuItem>
              </Menu>
            </div>
           )} 
            </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
      <div
        className="modal fade primary_modal"
        id="connect_wallet_modal"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Connect Wallet
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="m-2 mb-3">
                  <a target="_blank" className="mb-3 d-block">
                    <button
                      type="button"
                      className="connect_wallet_button"
                      onClick={() => handleWallet()}
                    >
                      <img
                        src={require("../assets/images/metamask.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>MetaMask</span>
                    </button>
                  </a>
                  <a target="_blank" className="mb-3 d-block">
                    <button
                      type="button"
                      className="connect_wallet_button"
                      onClick={() => connectTrustwallet()}
                    >
                      <img
                        src={require("../assets/images/trustwallet.png")}
                        height="28"
                        width="28"
                        className="img-fluid"
                      />{" "}
                      <span>Trust Wallet</span>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
