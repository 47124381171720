let EnvName = "production";
let Front_URL = "";
let Back_URL = "";
let v1Url = "";
let RpcUrl = "";
let limit = "12";
let limitMax = "3000";
let fee = 2500000000000000000;
let decimalvalues = 1000000000000000000;
let toFixed = 6;
let IPFS_IMG = "https://ipfs.io/ipfs";
const singleType = 721;
const multipleType = 1155;
var providercon = null;
let networkVersion = 56; //mainnet -56,testnet-97
let currencySymbol = "BNB";
let equalTokenSymbol = "WBNB";
let tokenSymbol = "BNJR";
const ownerAddr = "0x49E1a781BffF290350d0EBA905D42823F2308F39";
var liverpcUrls = "";
var livechainid = 0;

// Testnet

var lottery = "";
var lotterAdmin = "";
var lotteryToken = "";

var singleContract = "";
var WbnbtokenAddr = "";
var PankukuAddr = "";
var ContractAddress = "";
// //Livenet
// const lotteryToken   = "0x28da3db8d1b6446c97ea09f718e40a081ad665e1";
// const lottery        = "0x3cB4129931d09CAb7637a11FA706999FE0252cff";
// const lotterAdmin    ="0x5c48cD9bc4d8F338aD51E1F7Ee792e0d962960EB";

// const singleContract =  "0x96e4ED44bc0dA71e61404920778Ee4C0e1C62b71";
// const WbnbtokenAddr  = "0xd38745B8B190Fb25B4d195ea299E64EebDFa227c";
// const PankukuAddr    = "0x28da3db8d1b6446c97ea09f718e40a081ad665e1";

// Testnet
// const lottery        = "0x09416115E926b440E0eb9C79530e5c0B768BF411"
// 0x039d96132CCF09D24a09EbEf7624A46A2A57F7Dd

const userContract721 = "";
const userContract1155 = "";
let toasterOption = {
  position: "top-right",
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
if (EnvName === "production") {
  Front_URL = "https://lottery.refitplusdefi.com/";
  Back_URL = "https://api.webonjour.com";
  v1Url = "https://lottery.refitplusdefi.com/v1";
  IPFS_IMG = "https://ipfs.io/ipfs";
  RpcUrl = "https://bsc-dataseed.binance.org/";
  liverpcUrls = "https://bscscan.com/";
  livechainid = 56;
  //
  lottery = "0x703c976CA913f94494c7594B48403d88f07040a5";
  lotterAdmin = "0x49E1a781BffF290350d0EBA905D42823F2308F39";
  lotteryToken = "0xCb83CF3E0FF414Dc9535B5329Cdc753D1b1D91f1";

  singleContract = "0xF739B96ad8B4efcebB7EcAffed2f560ACDB64517";
  WbnbtokenAddr = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  PankukuAddr = "0xCb83CF3E0FF414Dc9535B5329Cdc753D1b1D91f1";
  ContractAddress = "0x703c976CA913f94494c7594B48403d88f07040a5";
} else if (EnvName === "demo") {
  Front_URL = "https://demoapi.webonjour.com/";
  Back_URL = "https://demoapi.webonjour.com/";
  v1Url = "https://demoapi.webonjour.com/v1";
  IPFS_IMG = "https://ipfs.io/ipfs";
  RpcUrl = "https://bsc-dataseed1.binance.org:443/";
  liverpcUrls = "https://bsc-dataseed1.binance.org:443/";
  livechainid = 56;
  //
  lottery = "0x60dF5E3D560E24f035981FFe4C0f697c860B130B";
  lotterAdmin = "0x49E1a781BffF290350d0EBA905D42823F2308F39";
  lotteryToken = "0x498532dc25045289E34A5Be1A050b3B30C024fcc";

  singleContract = "0xF739B96ad8B4efcebB7EcAffed2f560ACDB64517";
  WbnbtokenAddr = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  PankukuAddr = "0x1dB3c1545df0c705cceEf4F7d33EFE8854bB8254";
  ContractAddress = "0x60dF5E3D560E24f035981FFe4C0f697c860B130B";
} else {
  // baseUrl= "http://localhost:2057/"
  Front_URL = "http://localhost:3000/";
  Back_URL = "http://localhost:2053";
  v1Url = "http://localhost:2053/v1";
  // Back_URL  = "http://212.71.233.123:5325/";
  // v1Url     = "http://212.71.233.123:5325/v1";
  IPFS_IMG = "https://ipfs.io/ipfs";
  RpcUrl = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  liverpcUrls = "https://data-seed-prebsc-1-s1.binance.org:8545/";
  livechainid = 97;
  //
  lottery = "0x19A49BD81E360D89Cd5457A9955615002Eb57F72";
  lotterAdmin = "0x49E1a781BffF290350d0EBA905D42823F2308F39";
  lotteryToken = "0x498532dc25045289E34A5Be1A050b3B30C024fcc";

  singleContract = "0xF739B96ad8B4efcebB7EcAffed2f560ACDB64517";
  WbnbtokenAddr = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
  PankukuAddr = "0x1dB3c1545df0c705cceEf4F7d33EFE8854bB8254";
  ContractAddress = "0x19A49BD81E360D89Cd5457A9955615002Eb57F72";
}

let key = {
  Front_URL: Front_URL,
  Back_URL: Back_URL,
  vUrl: v1Url,
  decimalvalues: decimalvalues,
  toFixed: toFixed,
  networkVersion: networkVersion,
  currencySymbol: currencySymbol,
  tokenSymbol: tokenSymbol,
  equalTokenSymbol: equalTokenSymbol,
  toasterOption: toasterOption,
  limit: limit,
  limitMax: limitMax,
  singleContract: singleContract,
  userContract721: userContract721,
  userContract1155: userContract1155,
  ownerAddr: ownerAddr,
  singleType: singleType,
  multipleType: multipleType,
  ipfsurl: IPFS_IMG,
  providercon: providercon,
  WbnbtokenAddr: WbnbtokenAddr,
  PankukuAddr: PankukuAddr,
  fee: fee,
  lotteryToken: lotteryToken,
  lottery: lottery,
  RpcUrl: RpcUrl,
  LotterytokenSymbol: "WOW",
  liverpcUrls: liverpcUrls,
  livechainid: livechainid,
  lotterAdmin: lotterAdmin,
  ContractAddress: ContractAddress,
  baseUrl: Back_URL,
};

export default key;
// "homepage": "https://nft.pankuku.net",
