import axios from "axios";
import config from "../lib/config/config";
import Web3 from "web3";
import { getprovider } from "../pages/seperate/provider";
import { toast } from "react-toastify";
// toast.configure();
let toasterOption = config.toasterOption;

export const getCurAddr = async () => {
  var { provider, address } = await getprovider();

  var currAddr = "";
  if (provider) {
    var web3 = new Web3(provider);
    if (provider) {
      var accVal = await web3.eth.getAccounts();
      currAddr = accVal[0];
      currAddr = currAddr.toString();
    }
  }
  return currAddr;
};

export const AddressUserDetails_GetOrSave_Action = async (Payload) => {
  try {
    let Resp = await axios({
      method: "post",
      url: `${config.Back_URL}/api/address/details/getorsave`,
      data: Payload,
    });
    return {
      data: Resp,
    };
  } catch (err) {
    return {
      // error: err.response.data
    };
  }
};

export const updatePrize = async () => {
  try {
    let respData = await axios({
      method: "get",
      url: `${config.Back_URL}/api/updatePrize`,
    });
    console.log(respData, "respData");
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};

export const storeEmail = async (data) => {
  try {
    let respData = await axios({
      method: "post",
      url: `${config.Back_URL}/api/storeEmail`,
      data
    });
    console.log(respData, "respData");
    return {
      loading: false,
      result: respData.data,
    };
  } catch (err) {
    return {
      loading: false,
      error: err.response.data.errors,
    };
  }
};